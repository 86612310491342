import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import CaseStudyArea from './CaseStudyArea/CaseStudyArea';

const CaseStudy01Banking = () => {
   return (
      <>
         <PageHelmet pageTitle="Remote Asset Monitoring for Financial Institution | Banking Industry" />
         <Header/>
         <CommonPageHeader title="Remote Asset Monitoring for Financial Institution" subtitle="Case Studies" />
         <CaseStudyArea/>
         <Footer/>
      </>
   );
};

export default CaseStudy01Banking;