import React, { useState } from "react";
import CommonCtaArea from "../../components/CommonCtaArea/CommonCtaArea";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import Header from "../../components/shared/Header";
import VideoPopup from "../../components/VideoPopup/VideoPopup";
import useGlobalContext from "../../hooks/useGlobalContext";
import AboutSustainableOne from './AboutSustainableOne/AboutSustainableOne';
import AboutSustainableTwo from './AboutSustainableTwo/AboutSustainableTwo';
import AboutSustainableThree from './AboutSustainableThree/AboutSustainableThree';
import AboutSustainableFour from './AboutSustainableFour/AboutSustainableFour';
import AboutSustainableFive from './AboutSustainableFive/AboutSustainableFive';
import AboutSustainableSix from './AboutSustainableSix/AboutSustainableSix';

const SustainabilityAbout = () => {
  const { setIsVideoOpen } = useGlobalContext();
  const [activeVideoId, setActiveVideoId] = useState();

  console.log("url test===>", activeVideoId);

  return (
    <>
      <PageHelmet pageTitle="About Sustainability" />
      <Header />
      <CommonPageHeader
        title="About Sustainability"
        subtitle="Sustainability"
      />
      {/* <AboutSustainableOurServices /> */}
      <AboutSustainableOne
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("Rxe0u9QXpFI")}
      />
      <AboutSustainableTwo
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("kn9Kyi58C60")}
      />
      <AboutSustainableThree
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("7lzR1LC8a10")}
      />
      <AboutSustainableFour
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("D1hYYcCvueA")}
      />
      <AboutSustainableFive
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("D1hYYcCvueA")}
      />
      <AboutSustainableSix
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("D1hYYcCvueA")}
      />
      <CommonCtaArea title="Sustainability Transformation" linkText="Start Now" />
      <VideoPopup videoId={activeVideoId} />
      <Footer />
    </>
  );
};

export default SustainabilityAbout;
