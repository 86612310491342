import React from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';

const ServicesThree = () => {
   return (
      <>
         <section className="capabilities__area p-relative black-bg-2 pt-180 pb-155 fix">
            <div className="capabilities__thumb p-absolute" style={{ background:`url(assets/img/services/details/services-03.jpg)`
               , backgroundPosition: 'center', backgroundSize:'cover'}}></div>
            <div className="capabilities__shape p-absolute wow fadeInLeft" >
               <img src="assets/img/capabilities/capabilities-shape.png" alt="shape"/>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="capabilities__content wow fadeInUp" data-wow-delay=".4s">
                        <div className="section__title section__title-2 mb-60">
                           <h2 className="white-color">Decision-making as a Service</h2>
                        </div>
                        <div className="capabilities__content mb-40">
                           <p className='white-color'>We build and run scalable, responsive, and fault-tolerant cloud-native applications on public, private, and hybrid clouds for effortless infrastructure management and enhanced customer experiences. SOLiD Inspire Platform “MyPrism” is an enterprise orchestrator platform that offers ‘Integration as a Service” and “Decision as a Service” to enterprises</p>
                        </div>
                        <Link to="/platform-development" className="z-btn z-btn-border white-color" >Learn more<i > <CgArrowLongRight /> </i></Link> 
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesThree;