import React from 'react';
import { Link } from 'react-router-dom';

const SustainabilityESG03DetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>ESG Transformation</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><Link to="/future-proof-your-business">Future-proof your Business</Link></li>                                    
                                    <li><Link to="/making-achieving-net-zero-pledges">Making and Achieving Net Zero Pledges</Link></li>
                                    <li><Link to="/unleashing-the-s-g-factors-in-esg-transformation">Unleashing the S and G factors in ESG transformation</Link></li>
                                    <li><Link to="/the-winning-formula-for-sustainable-business-success">The Winning Formula for Sustainable Business Success</Link></li>
                                    <li><Link to="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024">Sustainability Starts at Home: How to Live a Greener Life in 2024</Link></li>
                                    <li><Link to="/esg-beyond-2024-a-glimpse-into-the-future-of-sustainable-business">ESG Beyond 2024: A Glimpse into the Future of Sustainable Business</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="services__text">
                        <p>It is no secret that climate change is a global crisis caused by human activities. Businesses are a significant contributor to the emission of greenhouse gases, through the supply chains, energy consumption, operations, etc. </p>
                        <p>In recent years climate change awareness and activism have reached new heights with the 2015 Paris Agreement setting climate targets for 2030, the need for actions to reduce the impacts and risks of climate change has become paramount.</p>
                        <p>Besides being important contributors to climate change, every business, in turn, will also be impacted by the effects, directly or indirectly. These effects include water scarcity, supply chain shortages, extreme weather events, detrimental changes to their markets, cost of operations, investor trust, and product quality & services.</p>
                     </div>
                     <div className="services__text  mb-20">
                        <h3>Key Takeaway</h3>
                        <p>Amidst the turmoil and confusion caused by climate change, it is possible to convert these challenges into new opportunities and make profits. </p>
                        <p>These opportunities include, but are not limited to:</p>
                     </div>
                     <div className="services__list mb-40">
                        <ul>
                           <li>Improving supply chain risks</li>
                           <li>Driving internal innovation</li>
                           <li>Building brand loyalty</li>
                           <li>Reducing carbon footprint</li>
                           <li>Adopting new climate-friendly technology</li>
                        </ul>
                     </div>
                     <div className="services__text">
                        <p>From small businesses to big MNCs, everyone can make a difference. By sharing best practices and understanding the need to scale up sustainable solutions, we can hope to create a solid collective impact.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default SustainabilityESG03DetailsArea;