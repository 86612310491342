import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import SustainabilityESG04DetailsArea from './SustainabilityESG04DetailsArea/SustainabilityESG04DetailsArea';
import SustainabilityESG04Header from './SustainabilityESG04Header/SustainabilityESG04Header';

const SustainabilityESG04 = () => {
   return (
      <>
         <PageHelmet pageTitle="The Winning Formula for Sustainable Business Success" />

         <Header/>
         <SustainabilityESG04Header />
         <SustainabilityESG04DetailsArea />
         <Footer />
      </>
   );
};

export default SustainabilityESG04;