import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import AboutWhoWeAre from './AboutWhoWeAre/AboutWhoWeAre';
import AboutIndustries from './AboutIndustries/AboutIndustries';
import Header from '../../components/shared/Header';
import AboutVision from './AboutVision/AboutVision';
import AboutHowWeDo from './AboutHowWeDo/AboutHowWeDo';

const About = () => {
   return (
      <>
         <PageHelmet pageTitle="About Us" />

         <Header/>
         <CommonPageHeader title="About Us" subtitle="About" />
         <AboutWhoWeAre/>
         <AboutHowWeDo/>
         <AboutVision/>
         <AboutIndustries/>
         <CommonCtaArea title="Talk to us to realise your digital ambition" linkText="I’m In" />
         <Footer/>
      </>
   );
};

export default About;