import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { Link } from "react-router-dom";

const AboutSustainabilityFive = () => {
  return (
    <>
      <section className="capabilities__area p-relative pt-180 pb-155 fix">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              <div
                className="capabilities__content wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="section__title section__title-2 mb-60">
                  <h3>
                  Sustainability Starts at Home: How to Live a Greener Life in 2024 
                  </h3>
                </div>
                <div className="capabilities__content">
                  <p>
                  Understanding how our lifestyle decisions affect the environment and coming up with solutions to help everyone live better, lighter lives are key components of sustainable living. In 2024, more and more people are leading more environmentally friendly lives, yet many find it difficult to break old behaviours. Here are a few useful suggestions to help us live a more sustainable life: {" "}
                  </p>
                  {/* <p className='white-color'>In recent years climate change awareness and activism have reached new heights with the 2015 Paris Agreement setting climate targets for 2030, the need for actions to reduce the impacts and risks of climate change has become paramount.</p> */}
                  {/* <p className='white-color'>Besides being important contributors to climate change, every business, in turn, will also be impacted by the effects, directly or indirectly. These effects include water scarcity, supply chain shortages, extreme weather events, detrimental changes to their markets, cost of operations, investor trust, and product quality & services</p> */}
                </div>
                <Link
                  to="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024"
                  className="z-btn"
                >
                  Learn more
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="m-img">
                <img src="assets/img/sustainable/Picture-5.jpg" className="border m-img rounded-10px" alt="" />
                {/* <div className="about__shape">
                  <img src="assets/img/about/red-shape.png" alt="" />
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSustainabilityFive;
