import React from 'react';
import { FaCheck } from 'react-icons/fa';

const ServicesOurServices = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
            {/* <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 mb-85 text-center">
                        <h2>As a heading to this page add this - We understand hardware software & peopleware</h2>
                     </div>
                  </div>
               </div> */}
               <div className="row">
                  <div className="col-md-6">
                     <div className="section__content wow fadeInUp" data-wow-delay=".2s">
                        <p>Digital Transformation is an organizational change management journey, involving people, processes, and technology that results in continuous improvement of the digital maturity of a business.</p>
                        <p>Digital is the transformation tool that unleashes the full potential of a business. Digitalization is not the goal. Business growth is. Improving process efficiency is. It is about being more, doing more with less. </p>
                        <h3>Everything you need to transform your business. </h3>
                        <p>SOLiD Inspire has the vision to enable enterprises to achieve the digital state by providing a business orchestrator platform that bridges the gap between strategy and execution.</p>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="section__content wow fadeInUp" data-wow-delay=".2s">
                        <h3>What Problem do we solve?</h3>
                        <p><b>Less than 8% of organizations that embark on the Digital Transformation journey succeed. Most failures occur due to </b></p>
                        
                     </div>
                     <div className="section__list mb-30">
                           <ul>
                              <li ><span><i > <FaCheck/> </i>Misaligned business and technology strategy</span></li>
                              <li ><span><i > <FaCheck/> </i>Disconnected people, processes, technology, and data systems</span></li>
                              <li ><span><i > <FaCheck/> </i>Failure to integrate Innovative technologies with legacy system</span></li>
                              <li ><span><i > <FaCheck/> </i>Less than 10% of organizations create value from data</span></li>
                           </ul>
                        </div>
                        <div className="section__content wow fadeInUp" data-wow-delay=".2s">
                        <p>Enter SOLiD Inspire. Our approach is to solve the above problems by offering,</p>
                        </div>
                  </div>
               </div>
               {/* <div className="row">
                  <SingleService icon="1" linkService="/enterprise-architecture" title="Enterprise Architecture-as-a-service" serviceDetailsContent="EA is the blueprint or navigation map for every successful transformation journey. EA looks at every organization through 4 different lenses – Business processes, Data, Technology, and Applications. This service creates the “As-Is” view of the enterprises, identifies gaps or opportunities for value realization then defines the future state based on future business aspirations." />
                  <SingleService icon="3" linkService="/information-security" title="Information Security as a Service" serviceDetailsContent="Protecting every business from any data-related vulnerabilities is one of the key priorities for the business, especially in the new normal world. The urgency of this service is also driven by the compliance and Governance laws in recent times We create and implement security systems and solutions to safeguard your software systems, networks, and data centers from unauthorized access, disclosure, use/misuse, destruction, modification, or disruption." />
                  <SingleService icon="4" linkService="/platform-development" title="Decision-making as a Service" serviceDetailsContent="We build and run scalable, responsive, and fault-tolerant cloud-native applications on public, private, and hybrid clouds for effortless infrastructure management and enhanced customer experiences. SOLiD Inspire Platform “MyPrism” is an enterprise orchestrator platform that offers ‘Integration as a Service” and “Decision as a Service” to enterprises" />
                  <SingleService icon="2" linkService="/internet-of-value" title="Internet of Everything" serviceDetailsContent="SOLiD Inspire is one of the leading IoT solution providers offering end-to-end Industry 4.0 solutions for enterprises comprising. Our optimized DNA (Device-Network-Application) solutions create a smart, connected, and safer world in manufacturing, logistics (Internet of Moving Things), and energy transition industries." />
               </div> */}
            </div>
         </section>
      </>
   );
};

export default ServicesOurServices;