import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoFaq from './HomeTwoFaq/HomeTwoFaq';
import HomeTwoFeatures from './HomeTwoFeatures/HomeTwoFeatures';
import HomeMyPrism from './HomeMyPrism/HomeMyPrism';
import Footer from '../../components/shared/Footer';
import Header from '../../components/shared/Header';
import HomeTwoHeroSlider from './HomeTwoHeroSlider/HomeTwoHeroSlider';

const HomeTwo = () => {
   return (
      <>
         <PageHelmet pageTitle="SOLID iNSPiRE" />
         <Header/>
         <HomeTwoHeroSlider/>
         <HomeTwoFeatures/>
         <HomeTwoFaq/>
         {/* <HomeTwoAchievement/>
         <HomeTwoExpertArea/> */}
         {/* <HomeTwoCounter/> */}
         {/* <HomeTwoTestimonial/>
         <HomeTwoCaseArea/>
         <HomeTwoBlogs/> */}
         <HomeMyPrism/>
         <CommonCtaArea  talk="Let’s Talk" title="Together let’s create game-changing digital experiences" linkText="Get in Touch" />
         <Footer/>
      </>
   );
};

export default HomeTwo;