import React from 'react';
import BlogLeftSideBar from '../../Blogs/BlogsArea/BlogLeftSideBar';

const BlogDetailsArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                           <p>A professor asked the student, seated in an auditorium to take up a “simple task” “Can you move the seat you are right now sitting in, to the right corner of the room?” The chair was firmly fixed to the ground and the student was wondering why an erudite professor would come up with such an impossible request. Without causing further embarrassment to the student, the professor said “What stopped you from asking for a screw driver? “</p>
                           <p>Most often, in the world we live in, we all act like the student and limit our own capability by putting self-imposed constraints. We stress ourselves, more than we should, by not asking for resources. An entrepreneur is one who gets the job done, by using other people’s resources. Deep smart people in the organization leverage the resources of the network they build within the organization. Every individual operates with an ecosystem and may be viewed as a node in a network. The success of the team is essentially the success of the network of people operating in the environment. The power of the network grows significantly when every individual starts leveraging each other’s strength and taps onto each other’s resources. Sometimes individuals operate in multiple ecosystems and one can actually tap into resources of a different network using a common node. Think LinkedIn’s second order connections as an analogy.</p>
                           <p>But everyone is this world seems so busy doing their own work. How would someone find time to help? What is the incentive to help?</p>
                           <p>The short answer is everybody seems busy because they are constrained by their own capability and limited by their own resources. People will help, because study shows most human beings are altruist in nature. One of the reasons Quora is hugely successful today is because, strangers keep answering questions of others, without any financial incentive. But, we seldom ask.</p>
                           <p>In order to get somebody help your own job two basic qualities needs to be nurtured</p>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>Give, before you ask : Start by helping others on the job and make others successful in their job. This is the only way one can grow both as an individual and as a professional. The ROI of giving is immense.</li>
                              <li>Think like a salesman: In a way all of us are constantly selling – our ideas, our vision, our dreams, our products. Enrolling others into one’s vision is an art and needs constant practice. If people see genuine passion in what you do, they usually come forward and help.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-40">
                           <p>Next time, when you are given a task, do not feel constrained by your own capability. We are more powerful, and a lot more resourceful than we think. We are as resourceful, as powerful as the network we live in. Probably,one of the reasons why successful people seldom eat lunch alone.</p>
                        </div>

                       
                     </div>
                  </div>

                  <BlogLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;