import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyLeftSideBar from '../../CaseStudies/CaseStudiesArea/CaseStudyLeftSideBar';

const CaseStudyArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                     <div className="blog__text mb-45">
                           <p>
                           This retail organization strived to revolutionize its digital capabilities and elevate customer engagement. Confronted with operational inefficiencies and the absence of e-commerce, they are determined to transform inventory management and boost profitability. 
                           </p>
                        </div>
                        
                        <div className="blog__text mb-45">
                           <h4>Challenges</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>Absence of e-commerce and omnichannel experience.</li>
                              <li>2-day data synchronization lag between ERP and Point-of-Sale systems.</li>
                              <li>Untracked item expiry leads to waste.</li>
                              <li>Disconnected operations affecting profitability.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Solution</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>Streamlined operations with integrated processes.</li>
                              <li>Launched e-commerce with features like Product Kitting and Advanced Shipment Notices.</li>
                              <li>Upgraded ERP for real-time integration with Point-of-Sale systems.</li>
                              <li>Improved warehouse management and supplier coordination.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Business Value</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>Increased sales and profit margins.</li>
                              <li>Enhanced customer satisfaction.</li>
                              <li>Reduced waste through better inventory management.</li>
                              <li>Near real-time operational visibility for improved planning.</li>
                           </ul>
                        </div>
                        <div>                           
                           <Link to="/assets/case-studies/solid-inspire-case-study-food-and-retail.pdf" target='_blank' className='card download-casestudy stretched-link'>
                              <p className='mb-0'>Download <br /> Food & Retail <br /> Case Stady</p>
                              <img src="/assets/img/icon/download-pdf.png" className='ml-30 p-2 img-fluid' alt="" />                           
                           </Link>
                        </div>
                       
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <CaseStudyLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default CaseStudyArea;