import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import SustainabilityESG03DetailsArea from './SustainabilityESG03DetailsArea/SustainabilityESG03DetailsArea';
import SustainabilityESG03Header from './SustainabilityESG03Header/SustainabilityESG03Header';

const SustainabilityESG03 = () => {
   return (
      <>
         <PageHelmet pageTitle="Unleashing the S and G factors in ESG transformation" />

         <Header/>
         <SustainabilityESG03Header />
         <SustainabilityESG03DetailsArea />
         <Footer />
      </>
   );
};

export default SustainabilityESG03;