import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";
import VideoSingleItem from "../../../components/VideoSingleItem/VideoSingleItem";

const AboutSustainableTwo = ({ open, hanleUrl }) => {
  return (
    <>
      <section className="about__area pb-200 pt-100 black-bg-2 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              {
                <VideoSingleItem
                  image="2"
                  user_image="2"
                  title="Making and Achieving Net Zero Pledges"
                  video_icon={
                    <div className="blog__play p-absolute">
                      <button
                        onClick={() => {
                          open(true);
                          hanleUrl();
                        }}
                        data-fancybox
                      >
                        {" "}
                        <i>
                          {" "}
                          <FiPlay />{" "}
                        </i>
                      </button>
                    </div>
                  }
                />
              }
              {/* <div className="about__thumb m-img">
                        <img src="assets/img/services/details/services-02.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div> */}
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  <h3  className="white-color">Making and Achieving Net Zero Pledges</h3>
                </div>
                <p  className="white-color">
                  Simply put, Net Zero will be achieved when all the emissions
                  that are released by human activities (in this context,
                  business operations) are balanced out by removing carbon from
                  the atmosphere, a process known as carbon removal. Carbon
                  removal can be achieved through natural processes (vegetation,
                  forests, etc) and human interventions like carbon capture &
                  storage. To reach net zero goals, within a specified time,
                  nations, countries, businesses, and even individuals, have
                  issued net zero pledges.{" "}
                </p>
                <Link
                  to="/making-achieving-net-zero-pledges"
                  className="z-btn  z-btn-border white-color"
                >
                  Learn more
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSustainableTwo;
