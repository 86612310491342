import React from 'react';
import { Link } from 'react-router-dom';

const PortfolioArea = () => {
   return (
      <>
         <section className='pb-200 pt-100'>
            <div className='container'>
               <div class="row">
                  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div class="section__title section__title-3 mb-85 text-center">
                        <h2>Industries we have transformed</h2>
                     </div>
                  </div>
               </div>
               <div className='row'>
                  <div className='col-md-3'>
                     <Link to="/automotive" className="card border-0 mb-30 cursor-pointer">
                        <img src="assets/img/industry/automotive.jpg" className='rounded-3 img-fluid' alt="" />
                        <div className="card-body">
                           <h4 class="card-title text-center">Automotive</h4>
                        </div>
                     </Link>
                  </div>
                  <div className='col-md-3'>
                     <div className="card border-0 mb-30">
                        <img src="assets/img/industry/manufacturing.jpg" className='rounded-3 img-fluid' alt="" />
                        <div className="card-body">
                           <h4 class="card-title text-center">Manufacturing</h4>
                        </div>
                     </div>
                  </div>
                  <div className='col-md-3'>
                     <Link to="/retail" className="card border-0 mb-30 cursor-pointer">
                        <img src="assets/img/industry/retail.jpg" className='rounded-3 img-fluid' alt="" />
                        <div className="card-body">
                           <h4 class="card-title text-center">Food & Retail</h4>
                        </div>
                     </Link>
                  </div>
                  <div className='col-md-3'>
                     <Link to="/construction" className="card border-0 mb-30 cursor-pointer">
                        <img src="assets/img/industry/construction.jpg" className='rounded-3 img-fluid' alt="" />
                        <div className="card-body">
                           <h4 class="card-title text-center">Construction</h4>
                        </div>
                     </Link>
                  </div>
                  <div className='col-md-3'>
                     <Link to="/banking" className="card border-0 mb-30 cursor-pointer">
                        <img src="assets/img/industry/banking.jpg" className='rounded-3 img-fluid' alt="" />
                        <div className="card-body">
                           <h4 class="card-title text-center">Banking</h4>

                        </div>
                     </Link>
                  </div>
                  <div className='col-md-3'>
                     <Link to="/energy" className="card border-0 mb-30 cursor-pointer">
                        <img src="assets/img/industry/energy.jpg" className='rounded-3 img-fluid' alt="" />
                        <div className="card-body">
                           <h4 class="card-title text-center">Energy Transition</h4>
                        </div>
                     </Link>
                  </div>
                  <div className='col-md-3'>
                     <Link to="/airline" className="card border-0 mb-3 cursor-pointer">
                        <img src="assets/img/industry/airline.jpg" className='rounded-3 img-fluid' alt="" />
                        <div className="card-body">
                           <h4 class="card-title text-center">Airline</h4>
                        </div>
                     </Link>
                  </div>
                  <div className='col-md-3'>
                     <Link to="/healthcare" className="card border-0 mb-30 cursor-pointer">
                        <img src="assets/img/industry/healthcare.jpg" className='rounded-3 img-fluid' alt="" />
                        <div className="card-body">
                           <h4 class="card-title text-center">Healthcare</h4>
                        </div>
                     </Link>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default PortfolioArea;