import React from 'react';
import { Link } from 'react-router-dom';

const SustainabilityESG05DetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>ESG Transformation</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><Link to="/future-proof-your-business">Future-proof your Business</Link></li>                                    
                                    <li><Link to="/making-achieving-net-zero-pledges">Making and Achieving Net Zero Pledges</Link></li>
                                    <li><Link to="/unleashing-the-s-g-factors-in-esg-transformation">Unleashing the S and G factors in ESG transformation</Link></li>
                                    <li><Link to="/the-winning-formula-for-sustainable-business-success">The Winning Formula for Sustainable Business Success</Link></li>
                                    <li><Link to="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024">Sustainability Starts at Home: How to Live a Greener Life in 2024</Link></li>
                                    <li><Link to="/esg-beyond-2024-a-glimpse-into-the-future-of-sustainable-business">ESG Beyond 2024: A Glimpse into the Future of Sustainable Business</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <p>Understanding how our lifestyle decisions affect the environment and coming up with solutions to help everyone live better, lighter lives are key components of sustainable living. In 2024, more and more people are leading more environmentally friendly lives, yet many find it difficult to break old behaviours. Here are a few useful suggestions to help us live a more sustainable life: </p>
                        </div>

                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>1. Consumption</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <img className="blog__img_left " src="assets/img/blog/blog-6/blog-6-1.jpg" alt=""/>                        
                           <p>Every year, we discard one billion tonnes of food. Methane, a more potent greenhouse gas than carbon dioxide, is frequently produced by food waste. </p>
                           <p>To eat more sustainably, however, you can make a few easy adjustments that also frequently save money. These include eating in season and locally, as well as consuming more plants—especially beans and greens—and consuming less meat. </p>
                           <p>Because beans can fix nitrogen from the air into nutrients, they don't need nitrogen fertilizers, which are made partially from natural gas. </p>
                           <p>An excellent place to start would be one day each week when you consume no meat. Though they can be pricey, eating manufactured "mock meats" can be a first step toward a more plant-based diet.</p>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>2. Travel</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                        <img className="blog__img_right " src="assets/img/blog/blog-6/blog-6-2.jpg" alt="" />
                           <p>Whether it's to the university, work, school, or the store, everyone must travel. Traveling sustainably requires balance. </p>
                           <p>The greenest mode of transportation is active transportation, such as walking, bicycling, and wheeling, which keeps you and your kids healthier and fitter while emitting no carbon dioxide. If possible, try substituting active travel options for one or two weekly automobile trips. </p>
                           <p>When traveling shorter distances in metropolitan areas, active transportation is frequently more affordable and quicker than driving a car. Additionally, it lessens traffic, which is a major contributor to city air pollution. </p>
                           <p>Compared to driving and flying, lengthier trips are more environmentally benign when taken by train or bus. To get the lowest tickets, though, you frequently need to make advance plans. </p>                    

                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>3. Utilization of Energy </h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <img className="blog__img_left " src="assets/img/blog/blog-6/blog-6-3.jpg" alt="" />
                       
                           <p>The cost of the energy we consume at home is rising, and it contributes significantly to greenhouse gas emissions. Modest adjustments to our daily energy consumption can have a significant impact on our household emissions and costs. </p>
                           <p>Most of these adjustments are simple and practical. When you depart a room, flip out the lights. Cover the saucepan and cook the food. Reduce the temperature in your house by 1°C. Wash dishes and clothing in cooler weather. Shower for shorter periods. </p>
                           <p>When not in use, unplug appliances like microwaves and chargers; do the same for fully charged electronics. Additionally, swap out damaged halogen lightbulbs for more energy-efficient LED models. </p>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>4. Fast Fashion</h4>
                        </div>
                     </div>
                     <div className="row mb-45">

                        <div className="col-xl-12 col-lg-12">
                        <img className="blog__img_right " src="assets/img/blog/blog-6/blog-6-4.jpg" alt="" />
                           <p>Individuals enjoy purchasing new clothing. However, "fast fashion" comes at an astronomically high social and environmental cost. Over 92 million tonnes of garbage are produced annually by the fashion industry, most of which is burned, dumped in landfills, or shipped to underdeveloped nations. </p>
                           <p>There exist numerous approaches to being both eco-friendly and stylish. To ensure that anything you buy will "work" with what you already have, start by organizing your wardrobe so you know what you have before you go shopping. </p>
                           <p>Save damaged goods from being thrown away; a plethora of YouTube videos are available to assist with clothing and accessory repairs. By employing repair techniques like Sashiko stitching, you can even add a personal touch to your outfit by repairing a noticeable part of it. </p>
                       
                          
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>5. Waste Management</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <img className="blog__img_left " src="assets/img/blog/blog-6/blog-6-5.jpg" alt="" />
                       
                          <p>Almost 2 billion metric tons of solid municipal garbage are produced yearly. By 2050, this number is predicted to rise by 70%. We can make a lot of little adjustments to cut down on the quantity we throw in our trash. </p>
                          <p>Making a shopping list will help you buy less on impulse and decrease overspending. Bring reusable shopping bags along with you and free shop packaging. Several stores sell food without excessive packaging, such as zero-waste stores, where patrons are invited to bring their containers to fill and replenish with bulk whole foods.</p>
                          <p>Make sure you are aware of what can be recycled in your area and heed the instructions given. Cutting waste lowers pollution, weekly expenses, and wasteful resource consumption. </p>
                          <p>We can all work together to create a future that is more sustainable by implementing tiny lifestyle adjustments. </p>
                          <p>In conclusion, living a sustainable life is crucial for the well-being of our planet. Implementing small changes in our daily routine can have a significant impact on the environment. By eating more plants, using sustainable modes of transportation, consuming energy wisely, choosing eco-friendly clothing options, and managing waste effectively, we can contribute towards a greener and healthier planet. It is up to all of us to take responsibility and make conscious choices for a sustainable future. </p>
                        </div>
                     </div>
                     {/* <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Head, Heart, and Spine </b>– Three Critical Leadership Organs: Head (Competence), Heart (Compassion) and Spine (Character) are the building blocks of leadership. You need a Head to think and analyze data and complex situations in order to make logical decisions. You need a Heart to “listen” (not hear) and understand other person’s point of view. You can win in relationships, both professional and personal only when you have developed the capability to think like the person you want to win over. You need a Spine to stand for what you believe in. If you do not stand for Something you tend to fall for Everything.</li>
                              <li><b>Ask Both “Why” and “Why Not” Questions:</b> It is important to wear dual hats of a manager and a leader in a corporate environment. As manager, it pays to be paranoid in order to assess risks of business environment and manage the details. Hence you need to ask a lot of “Why” questions to get into the heart of situations and execute flawlessly. As a leader, you need to create a vision for the team and constantly challenge them to a higher level of performance by asking “Why Not”?</li>
                              <li><b>Take Accountability for Problems:</b> Success has many fathers, but failure is, more often, orphan. However, both success and failure definitions are merely perceptions of individuals and both can only be judged by the impartial verdict of Time. True leaders take a little less share of success and little more share of failures than they actually deserve. Why don’t you challenge yourself by adopting a “perceived failure situation” and try making a difference? Remember, the quality of your life is defined by the problem you choose to solve in your life.</li>
                              <li><b>Empower Yourself:</b> Power is the freedom you choose to give yourself to do the right things in life. The power you derive from your titles is transient and should not be relied upon. Real power comes from knowledge and the ability to take responsibility and accountability for your actions. Hence Power is earned, and not derived. Only You can empower Yourself, nobody else can. Power is not a means to satisfy one’s ego. Power should make you humble and responsible. If it does not, you are no different from the infant obsessed with the newest toy (power). Toy breaks and so does power, if not handled carefully.</li>
                              <li><b>Develop Healthy Respect for Yourself, Team and the Organization:</b> Some people have so much respect for their bosses that they have very little left for themselves. But leaders must have healthy self‐respect to stand by their beliefs. You can only develop passion at work and become successful, when you genuinely respect the core‐values of your team and that of the organization.</li>
                              <li><b>Learn to Defocus:</b> To focus on the “real job”, it is important to defocus from the non‐value adding, low impact, seemingly urgent (but not important) tasks. Motion without action is more dangerous than complete inaction. Do not get into the trap when you artificially make yourself too busy to take care of important things such as customer and people issues.</li>
                              <li><b>80‐20 Rule of Focus:</b> The ROI of playing to your strength is more than focusing too much on your weaknesses. Typically, one should spend no more than 20% of his/her time on the weaknesses and focus 80% of time in developing on one’s strengths. Given the limited bandwidth of life, why spend time on your weaknesses alone and become average when you have the option of strengthening your strengths and achieve excellence by doing what you love? However, one must correct one’s flaws to an extent where those do not become fatal. You can develop a team with complementary personal competencies so that the team completes each other.</li>
                              <li><b>Tell the Truth:</b> The ROI of honesty is undeniably immense. Dishonesty is much worse than incompetency because the latter is a correctable evil. If you are honest, you have to remember fewer things. Honesty will give the confidence to face any challenges at life and work. Moreover, the Truth “comes out of the closet” anyway. Resisting truth is delaying the eventuality artificially, ineffectively. Credibility is No 1 quality of a good leader. Credibility is earned by telling the truth, every time, no matter what the context and cost is.</li>
                              <li><b>Remember Names, Stories and Return Gestures:</b> Develop the habit remembering people’s names and the story you and him have created together. These little experiences, significant and apparently insignificant ones, added overall, make your life. If you want to be remembered, remember others. Give something back, in your own way, when your turn comes.</li>
                              <li><b>Be Passionate and Dispassionate Simultaneously:</b> Too much passion, at times clouds your vision from truth. As a leader, you need to make unbiased and objective decisions based on logic, data and intuition. You can do that only when you dissociate yourself from the problem and treat yourself as an “outsider”. Sometimes, you need to remind yourself that you are merely a custodian of your title/job and not the owner. Do not let your title define who you are.</li>

                           </ul>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div> */}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default SustainabilityESG05DetailsArea;