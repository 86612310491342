import React from 'react';
import { Link } from 'react-router-dom';

const SustainabilityESG06DetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>ESG Transformation</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><Link to="/future-proof-your-business">Future-proof your Business</Link></li>
                                    <li><Link to="/making-achieving-net-zero-pledges">Making and Achieving Net Zero Pledges</Link></li>
                                    <li><Link to="/unleashing-the-s-g-factors-in-esg-transformation">Unleashing the S and G factors in ESG transformation</Link></li>
                                    <li><Link to="/the-winning-formula-for-sustainable-business-success">The Winning Formula for Sustainable Business Success</Link></li>
                                    <li><Link to="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024">Sustainability Starts at Home: How to Live a Greener Life in 2024</Link></li>
                                    <li><Link to="/esg-beyond-2024-a-glimpse-into-the-future-of-sustainable-business">ESG Beyond 2024: A Glimpse into the Future of Sustainable Business</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <p>Environmental, social, and governance, or ESG, refers to the standards by which a business's ethical influence and sustainability are evaluated. Better regulatory pressure, more sustainability activities for smaller businesses, better supply chain openness, increasing demand for climate technologies, and decreased susceptibility to greenwashing and ESG-related hazards are some of the key ESG themes for 2024. Companies will be forced by these trends to include ESG in their operations, strategies, and communications to gain new competitive advantages and increase stakeholder engagement.</p>
                        </div>

                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>1. Sustainability in the Supply Chain Becomes the De-facto Norm</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <img className="blog__img_left " src="assets/img/sustainable/section-06/Image-1.jpg" alt="" />
                           <p>Businesses looking to comply with new rules and achieve emissions reduction goals across their whole value chain will make supply chain sustainability a top priority.</p>
                           <p>Reporting on supply chain sustainability will be mandatory for corporations starting in 2024, as per the ISSB guidelines and most ESG regulations. As a result, more businesses will make it necessary for suppliers to comply with their codes of conduct when it comes to ESG and emissions data visibility. For instance, Microsoft already mandates that its suppliers provide accurate, comprehensive, and scope 1-3 greenhouse gas (GHG) emissions data and has included ESG guidelines in its Code of Conduct for Suppliers. Additionally, Amazon has stated that starting in 2024, it will start to demand emissions statistics and decarbonization targets from its suppliers.</p>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>2. A Fresh Set of Rules for ESG Disclosure</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <img className="blog__img_right " src="assets/img/sustainable/section-06/Image-2.jpg" alt="" />
                           <p>The most significant ESG regulatory disclosure of 2023 was the introduction of California's Climate Accountability Package, the nation's first climate disclosure law. This means that starting in 2026, more than 10,000 US public and private enterprises must get ready to submit their climate risk impact statement and carbon footprint.</p>
                           <p>From the standpoint of US ESG regulations, the other big development is that the SEC's climate disclosure bill is still pending. Additionally, the finalization of the Corporate Sustainability Reporting Directive (CSRD) criteria in Europe means that businesses will begin gathering data for their initial reporting in 2025. There are currently 29 distinct national and regional ESG disclosure laws in existence worldwide. Globally speaking, we should anticipate a large increase in the number of regulators issuing ISSB-aligned ESG disclosure rules by 2024. </p>

                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>3. ESG Reporting Standardization</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">

                           <img className="blog__img_left " src="assets/img/sustainable/section-06/Image-3.jpg" alt="" />

                           <p>For ESG reporting, the publication of the first two standards by the International Sustainability Standards Board (ISSB) in mid-2023 was revolutionary. It also marked the start of a new phase of ESG reporting standard alignment.</p>
                           <p>When businesses are allowed to start utilizing the ISSB to report on their ESG performance in 2024, the ISSB will:</p>
                           <ul>
                              <li style={{ listStyle: 'disc', marginBottom: 10 }}>Assume the Taskforce on Climate-related Financial Disclosures' obligations to improve alignment in the reporting landscape (TCFD)</li>
                              <li style={{ listStyle: 'disc', marginBottom: 10 }}>Becoming part of the CDP climate disclosure survey</li>
                              <li style={{ listStyle: 'disc', marginBottom: 10 }}>Comply with all main worldwide reporting requirements, including the European Sustainability Reporting Requirements (ESRS), the Worldwide Reporting Initiative (GRI), and others.</li>
                           </ul>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>4. Improved ESG Reporting and Measuring</h4>
                        </div>
                     </div>
                     <div className="row mb-45">

                        <div className="col-xl-12 col-lg-12">
                           <img className="blog__img_right " src="assets/img/sustainable/section-06/Image-4.jpg" alt="" />
                           <p>More companies are reporting their ESG performance, leading to improved sustainability outcomes and ESG performance measurement techniques. The S&P 500 shares 98% of its sustainability reports, and ESG reporting is now available for 82% of the Russell 1000's bottom half. Businesses are also committing to high ESG goals, such as net zero emissions. In 2024, organizations are expected to focus on data quality to develop more effective strategies, improve risk assessment accuracy, and increase transparency and accountability in reporting.</p>


                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>5. A Rise in ESG Technology Expenditure</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <img className="blog__img_left " src="assets/img/sustainable/section-06/Image-5.jpg" alt="" />
                           <p>Companies anticipate raising their ESG technology spending in 2024 to start reporting, minimize growing ESG risks, and stay up to date with new compliance needs.</p>
                           <p>Companies are looking to outside vendors and software technologies to meet their ESG challenges to quickly launch their ESG initiatives. According to a Reuters article, businesses intend to increase their spending on outside software providers and solutions to stay in compliance with impending laws. According to Bloomberg research, 92% of CEOs intend to spend 10% or more on ESG in 2024, and 18% aim to spend 50% or more.</p>
                           <p>Technology integration into ESG data practices is a business strategy, not merely a compliance measure. It gives businesses the potential to show their dedication to sustainability, draw in investors, lower risks, and obtain a competitive advantage in a market that is becoming more and more ESG-focused.</p>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>6. Encouraging Improvements in ESG Performance</h4>
                        </div>
                     </div>
                     <div className="row mb-45">

                        <div className="col-xl-12 col-lg-12">
                           <img className="blog__img_right " src="assets/img/sustainable/section-06/Image-6.jpg" alt="" />
                           <p>Companies that enhance their ESG performance will receive rewards, emphasizing concrete achievements rather than vague promises. This will incentivize improvement and help identify those falling short of goals. Focusing solely on reducing carbon footprints may lead to success, while others may struggle to cut emissions. However, studies show that many businesses will fall short of their climate commitments, with only 4% achieving high-quality net-zero aims. As of 2022, fewer businesses are expected to meet their emissions objectives. As a result, promises will become less important than performance, with businesses penalized for subpar work and rewarded for improved performance. This trend underscores the importance of adjusting to a low-carbon economy and having clear plans in place.</p>


                        </div>
                     </div>
                     
                     <div className="row mb-45">

                        <div className="col-xl-12 col-lg-12">

                           <p>
                              <b>Measuring ESG performance is a fundamental change in how organizations will function in the coming years. ESG reporting will be more integrated, regulated, and visible in 2024 than it has ever been. Businesses that adopt an ESG approach will be in a better position to take advantage of opportunities, reduce risks, and improve their performance and reputation. There will be more scrutiny, criticism, and difficulties for those who reject or neglect ESG. For sustainability and long-term success, focusing on ESG is not just the moral, but also the prudent thing to do.</b>
                           </p>


                        </div>
                     </div>
                     {/* <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Head, Heart, and Spine </b>– Three Critical Leadership Organs: Head (Competence), Heart (Compassion) and Spine (Character) are the building blocks of leadership. You need a Head to think and analyze data and complex situations in order to make logical decisions. You need a Heart to “listen” (not hear) and understand other person’s point of view. You can win in relationships, both professional and personal only when you have developed the capability to think like the person you want to win over. You need a Spine to stand for what you believe in. If you do not stand for Something you tend to fall for Everything.</li>
                              <li><b>Ask Both “Why” and “Why Not” Questions:</b> It is important to wear dual hats of a manager and a leader in a corporate environment. As manager, it pays to be paranoid in order to assess risks of business environment and manage the details. Hence you need to ask a lot of “Why” questions to get into the heart of situations and execute flawlessly. As a leader, you need to create a vision for the team and constantly challenge them to a higher level of performance by asking “Why Not”?</li>
                              <li><b>Take Accountability for Problems:</b> Success has many fathers, but failure is, more often, orphan. However, both success and failure definitions are merely perceptions of individuals and both can only be judged by the impartial verdict of Time. True leaders take a little less share of success and little more share of failures than they actually deserve. Why don’t you challenge yourself by adopting a “perceived failure situation” and try making a difference? Remember, the quality of your life is defined by the problem you choose to solve in your life.</li>
                              <li><b>Empower Yourself:</b> Power is the freedom you choose to give yourself to do the right things in life. The power you derive from your titles is transient and should not be relied upon. Real power comes from knowledge and the ability to take responsibility and accountability for your actions. Hence Power is earned, and not derived. Only You can empower Yourself, nobody else can. Power is not a means to satisfy one’s ego. Power should make you humble and responsible. If it does not, you are no different from the infant obsessed with the newest toy (power). Toy breaks and so does power, if not handled carefully.</li>
                              <li><b>Develop Healthy Respect for Yourself, Team and the Organization:</b> Some people have so much respect for their bosses that they have very little left for themselves. But leaders must have healthy self‐respect to stand by their beliefs. You can only develop passion at work and become successful, when you genuinely respect the core‐values of your team and that of the organization.</li>
                              <li><b>Learn to Defocus:</b> To focus on the “real job”, it is important to defocus from the non‐value adding, low impact, seemingly urgent (but not important) tasks. Motion without action is more dangerous than complete inaction. Do not get into the trap when you artificially make yourself too busy to take care of important things such as customer and people issues.</li>
                              <li><b>80‐20 Rule of Focus:</b> The ROI of playing to your strength is more than focusing too much on your weaknesses. Typically, one should spend no more than 20% of his/her time on the weaknesses and focus 80% of time in developing on one’s strengths. Given the limited bandwidth of life, why spend time on your weaknesses alone and become average when you have the option of strengthening your strengths and achieve excellence by doing what you love? However, one must correct one’s flaws to an extent where those do not become fatal. You can develop a team with complementary personal competencies so that the team completes each other.</li>
                              <li><b>Tell the Truth:</b> The ROI of honesty is undeniably immense. Dishonesty is much worse than incompetency because the latter is a correctable evil. If you are honest, you have to remember fewer things. Honesty will give the confidence to face any challenges at life and work. Moreover, the Truth “comes out of the closet” anyway. Resisting truth is delaying the eventuality artificially, ineffectively. Credibility is No 1 quality of a good leader. Credibility is earned by telling the truth, every time, no matter what the context and cost is.</li>
                              <li><b>Remember Names, Stories and Return Gestures:</b> Develop the habit remembering people’s names and the story you and him have created together. These little experiences, significant and apparently insignificant ones, added overall, make your life. If you want to be remembered, remember others. Give something back, in your own way, when your turn comes.</li>
                              <li><b>Be Passionate and Dispassionate Simultaneously:</b> Too much passion, at times clouds your vision from truth. As a leader, you need to make unbiased and objective decisions based on logic, data and intuition. You can do that only when you dissociate yourself from the problem and treat yourself as an “outsider”. Sometimes, you need to remind yourself that you are merely a custodian of your title/job and not the owner. Do not let your title define who you are.</li>

                           </ul>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div> */}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default SustainabilityESG06DetailsArea;