import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import SustainableOurServices from './SustainableOurServices/SustainableOurServices';
import SustainableOne from './SustainableOne/SustainableOne';
import SustainableTwo from './SustainableTwo/SustainableTwo';
import SustainableThree from './SustainableThree/SustainableThree';
import SustainableFour from './SustainableFour/SustainableFour';

const Sustainable = () => {
   return (
      <>
         <PageHelmet pageTitle="Sustainable" />
         <Header/>
         <CommonPageHeader title="Sustainable" subtitle="Sustainable" />
         <SustainableOurServices />
         <SustainableOne />         
         <SustainableTwo />
         <SustainableThree />
         <SustainableFour />
         <CommonCtaArea title="It’s high-time you connect, integrate and strengthen your business functions" linkText="Start Now" />
         <Footer/>
      </>
   );
};

export default Sustainable;