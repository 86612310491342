import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyLeftSideBar from '../../CaseStudies/CaseStudiesArea/CaseStudyLeftSideBar';

// // all category
// const allCategory = ['see all', ...new Set(data.map(category => category.category))]
// // array unique items
// const arr = data;
// const uniqueItem = arr.filter((arr, index, self) =>
//    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const CaseStudyArea = () => {
   // // active btn
   // const [active, setActive] = useState('see all');
   // const [filterPortfolio, setFilterPortfolio] = useState(uniqueItem);
   // const [values, setValues] = useState(4);

   // // filtering portfolio data
   // const filterCategory = (category) => {
   //    setActive(category)
   //    if (category === 'see all') {
   //       return setFilterPortfolio(uniqueItem)
   //    }
   //    const remainingItems = data.filter((item) => item.category === category);
   //    setFilterPortfolio(remainingItems)
   // }
   // // handleLoadMore
   // const handleLoadMore = () => {
   //    setValues((value) => value + 1)
   // }
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__list mb-45">
                           <p>
                              <b>A financial institution provided loans to SMEs for asset purchases but faced challenges:</b>
                           </p>
                           <ul>
                              <li>No real-time visibility into customer business performance.</li>
                              <li>Difficulty tracking asset usage, leading to inefficient mortgage and loss management.</li>
                              <li>Limited ability to offer proactive services like maintenance alerts to optimize asset utilization.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Solution</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>The institution introduced remote asset monitoring, using trackers to capture asset usage data and evaluate business performance.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Business Value</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>360% improvement in mortgage and loss management within 9 months.</li>
                              <li>Expanded IoT-based services by 2029.</li>
                              <li>Preventive maintenance alerts reduced downtime and improved asset utilization.</li>
                           </ul>
                        </div>
                        <div>                           
                           <Link to="/assets/case-studies/solid-inspire-case-study-banking.pdf" target='_blank' className='card download-casestudy stretched-link'>
                              <p className='mb-0'>Download <br /> Banking Industy<br /> Case Stady</p>
                              <img src="/assets/img/icon/download-pdf.png" className='ml-30 p-2 img-fluid' alt="" />                           
                           </Link>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <CaseStudyLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default CaseStudyArea;