import React from 'react';
import { Link } from 'react-router-dom';

const ServicesIoTDetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>Services</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><Link to="/enterprise-architecture">Enterprise Architecture</Link></li>
                                    <li><Link to="/information-security">Information Security</Link></li>
                                    <li><Link to="/platform-development">Platform Development</Link></li>
                                    <li><Link to="/internet-of-value">Internet of Value</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="services__text">
                        <p>We build value for businesses through our “Internet of Value” services. We work by the theme “Smart. Safe. Connected.”</p>
                        <p>We SOLiD Inspire are problem solvers who use IoT as a tool to unleash the potential of enterprises and cities.</p>
                        <p>SOLiD Inspire’s ‘Internet of Value” business model comprises,</p>
                        
                     </div>
                     {/* <div className="services__img mb-45 w-img">
                        <img src="assets/img/services/details/services-01.jpg" alt="" />
                     </div> */}
                     <div className="services__list mb-40">
                        <p><strong>Asset monitoring and maintenance as a service:</strong></p>
                        <ul>
                           <li>Device only</li>
                           <li>Device and application in partnership with platform vendors</li>
                        </ul>
                     </div>
                     <div className="services__list mb-40">
                        <p><strong>Industrial safety as a service</strong></p>
                        <ul>
                           <li>Turnkey projects</li>
                           <li>Managed service</li>
                        </ul>
                     </div>
                     <div className="services__list mb-40">
                        <p><strong>Internet of Value as a Service</strong></p>
                        <ul>
                           <li>Personalized business metrics and dashboards based on IoT service value</li>
                        </ul>
                     </div>
                     <div className="services__text">
                     <p>Also, our Internet of Things (IoT) services help in the effectual collection and exchange of data from physical objects “things” embedded with sensors, software, and other tech, and process it for organization’s innovations, improvements, and all sorts of applications.</p>
                        <p>As an end-to-end IoT solution provider with deep expertise in industrial-grade modules, devices, smart gateway, and connectivity, we bridge the ecosystems between next-gen technology and global partners to reimagine IoT.</p>
                     </div>
                     <div className="services__list mb-40">
                        <p>Our expertise includes,</p>
                        <ul>
                           <li>Internet of Moving Things (IoMT)</li>
                           <li>Industrial Internet of Things (IIoT)</li>
                           <li>Internet of Everything (IoE)</li>
                        </ul>
                     </div>
                     <div className="services__text">
                        <p>We are domain experts in designing, developing, and deploying industrial-grade, scalable solutions for the Internet of moving things.</p>
                        <p>We increase operational efficiency in logistics by improved asset utilization. One can achieve 10-25% operational efficiency of enterprise assets through IoT solutions.</p>
                        <p>Our industrial safety and compliance services make 96% of factory accidents and more than 80% of crew accidents preventable.</p>
                        <p>Improving the redemption rate by preventing mortgage losses and reducing asset downtime through preventive maintenance are the few other benchmark values we add to businesses.</p>
                     </div>
                     <div className="services__list mb-40">
                        <p>On the whole, we promise our customers,</p>
                        <ul>
                           <li>Operational efficiency</li>
                           <li>Productivity enhancement</li>
                           <li>Safety & compliance</li>
                           <li>Servitization</li>
                        </ul>
                     </div>
                     <div className="services__img mb-45 w-img">
                     <iframe width="560" height="315" src="https://www.youtube.com/embed/_BH6DDtTLMs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesIoTDetailsArea;