import React from 'react';

const AboutWhoWeAre = () => {
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/about/about-1.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <span>Who we are</span>
                           <h2 className='text-64px'>We build smart, connected, agile organisations</h2>
                        </div>
                        <p>SOLiD Inspire offers a holistic approach to digital transformation, craving out strategic options for every enterprise’s growth. We help businesses and brands achieve their digital momentum with our technological skills, strategic thinking, innovation, and perfection.</p>
                        <p>Our team of top-grade professionals ensures that enterprises stay up-to-date with the latest technologies and deliver solutions that add brilliance to businesses.</p>
                        {/* <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck/> </i>Innovative ideas</span></li>
                              <li ><span><i > <FaCheck/> </i>Professional assistance</span></li>
                              <li ><span><i > <FaCheck/> </i>Financial advisory</span></li>
                           </ul>
                        </div>
                        <Link to="/services" className="z-btn " >What we do<i > <CgArrowLongRight /> </i></Link> */}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default AboutWhoWeAre;