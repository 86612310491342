import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Header from '../../components/shared/Header';
import ServicesPDDetailsArea from './ServicesPDDetailsArea/ServicesPDDetailsArea';

const ServicesPD = () => {
   return (
      <>
         <PageHelmet pageTitle="Platform Development" />

         <Header/>
         <CommonPageHeader
            title="Platform Development"
            subtitle="Our Services"
            />
         <ServicesPDDetailsArea />
         <Footer />
      </>
   );
};

export default ServicesPD;