import React from 'react';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
   return (
      <>


         <footer>
            <div className="footer__area black-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".2s">
                              <div className="footer__widget-title mb-30">
                                 <div className="logo">
                                    <Link to="/"  title='Solid Inspire Home'>
                                       <img src="assets/img/logo/solid-inspire-logo-white.svg"  alt="Solid Inspire Logo" />
                                    </Link>
                                 </div>
                              </div>
                              <div className="footer__widget-content footer__widget-content-2">
                                 {/* <p className="mb-30">Copyright © 2024 All Rights Reserved passion by <Link to="/">SOLID iNSPiRE</Link></p> */}
                                 <div className="footer__social footer__social-2 theme-social mb-30">
                                    <ul>
                                       {/* <li>
                                          <a href="#">
                                             <i ><FaFacebookF /></i>
                                             <i ><FaFacebookF /></i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="#">
                                             <i ><FaTwitter /> </i>
                                             <i ><FaTwitter /> </i>
                                          </a>
                                       </li> */}
                                       <li>
                                          <a href="https://www.linkedin.com/company/solidinspire/" target="_blank" rel="noopener noreferrer" title='LinkedIn'>
                                             <i ><FaLinkedin /> </i>
                                             <i ><FaLinkedin /> </i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="https://www.instagram.com/social_solidinspire/" target="_blank" rel="noopener noreferrer" title='Instagram'>
                                             <i ><FaInstagram /> </i>
                                             <i ><FaInstagram /> </i>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".4s">
                              <div className="footer__widget-title text-white">
                                 <h4>Company</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                    <ul>
                                       <li><a href="/about">About Us</a></li>
                                       <li><a href="/team">Team</a></li>
                                       <li><a href="/services">Services</a></li>
                                       <li><a href="/enterprises">Enterprises</a></li>
                                       <li><a href="/contact">Contact Us</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".6s">
                              <div className="footer__widget-title text-white">
                                 <h4>Resources</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                    <ul>
                                       <li><a href="/blogs">Blogs</a></li>
                                       <li><a href="/press-release">Media</a></li>
                                       <li><a href="/interviews">Interviews</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".8s">
                              <div className="footer__widget-title text-white">
                                 <h4>Services</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                    <ul>
                                       <li><a href="/enterprise-architecture">Enterprise Architecture</a></li>                                       
                                       <li><a href="/information-security">Information Security</a></li>
                                       <li><a href="/platform-development">Platform Development</a></li>
                                       <li><a href="/internet-of-value">Internet of Value</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer__copyright footer__copyright-2">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-12 text-center">
                           <div className="footer__copyright-text footer__copyright-text-2">
                              <p>Copyright © 2024 All Rights Reserved passion by <Link to="/">SOLID iNSPiRE</Link></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;