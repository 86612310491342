import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { Link } from "react-router-dom";

const AboutSustainableSix = () => {
  return (
    <>
      <section className="about__area pb-200 pt-100 black-bg-2 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
            <div className="m-img">
                <img src="assets/img/sustainable/section-06/Thumbnail.jpg" className="border m-img rounded-10px" alt="" />
                {/* <div className="about__shape">
                  <img src="assets/img/about/red-shape.png" alt="" />
                </div> */}
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  <h3 className="white-color">
                  ESG Beyond 2024: A Glimpse into the Future of Sustainable Business
                  </h3>
                </div>
                <p className="white-color">
                Environmental, social, and governance, or ESG, refers to the standards by which a business's ethical influence and sustainability are evaluated. Better regulatory pressure, more sustainability activities for smaller businesses, better supply chain openness, increasing demand for climate technologies, and decreased susceptibility to greenwashing and ESG-related hazards are some of the key ESG themes for 2024. Companies will be forced by these trends to include ESG in their operations, strategies, and communications to gain new competitive advantages and increase stakeholder engagement.
                </p>
                <Link
                  to="/esg-beyond-2024-a-glimpse-into-the-future-of-sustainable-business"
                  className="z-btn z-btn-border white-color"
                >
                  Learn more
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSustainableSix;
