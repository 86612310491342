import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import BlogsArea from './BlogsArea/BlogsArea';

const Blogs = () => {
   return (
      <>
         <PageHelmet pageTitle="SOLiD Inspire blogs educating businesses on what's new in the business and digital transformation" />

         <Header/>
         <CommonPageHeader title="Digital diary" subtitle="Blog" />
         <BlogsArea/>
         <Footer/>
      </>
   );
};

export default Blogs;