import React from 'react';
import BlogSingleItem from '../../../components/BlogSingleItem/BlogSingleItem';
import BlogLeftSideBar from './BlogLeftSideBar';

const BlogsArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__wrapper mr-50">

                        {/* <BlogSingleItem linkBlogDetails="/21-things-i-learned-in-2021" image="1" user_image="1" name="Mrinmoy Chakraborty" title="21 Things I learned in 2021" description="For a change, in this LinkedIn post I have refrained myself from any humble bragging related to my leadership accomplishments, academic achievements" /> */}
                        {/* <BlogSingleItem linkBlogDetails="/generosity-is-also-letting-others-give" image="2" user_image="1" name="Mrinmoy Chakraborty" title="Generosity is also letting others give" description="Generosity is also allowing others to give and accept with gratitude and grace“. I was speaking to one of my mentors, someone I have " />
                        <BlogSingleItem linkBlogDetails="/life-after-corona" image="3" user_image="1" name="Mrinmoy Chakraborty" title="Life after Corona" description="Yes, this too shall pass. After the storm, there will be calm. My predictions for the post Corona, wiser new world" />
                        <BlogSingleItem linkBlogDetails="/leading-with-heart-the-inexperienced-candidate" image="4" user_image="1" name="Mrinmoy Chakraborty" title="Leading With Heart : The Inexperienced Candidate" description="There are countless instances in the corporate world when a manager or a leader had to make a decision for a situation that does not exist " /> 
                        <BlogSingleItem linkBlogDetails="/be-more-do-more" image="5" user_image="1" name="Mrinmoy Chakraborty" title="Be more, Do More" description="A professor asked the student, seated in an auditorium to take up a “simple task” “Can you move the seat you are right now sitting in, to the right " />*/}
                        <BlogSingleItem linkBlogDetails="/tech-for-good-how-innovation-is-tackling-sustainability-challenges" image="17" user_image="2" name="Team Inspire" title="Tech for Good: How Innovation is Tackling Sustainability Challenges" description="The urgency of the climate crisis and environmental degradation demands creative solutions. Thankfully, the tech revolution is offering a helping hand. Tech for Good is a movement where technology is harnessed to address sustainability challenges and build a greener future." />
                        <BlogSingleItem linkBlogDetails="/from-awareness-to-action-practical-steps-for-sustainable-transformation" image="16" user_image="2" name="Team Inspire" title="From Awareness to Action: Practical Steps for Sustainable Transformation" description="In today's world, consumers, investors, and employees are increasingly looking to businesses that prioritize not just profit, but also the environment, society, and responsible governance." />
                        <BlogSingleItem linkBlogDetails="/unveiling-sustainable-investment-opportunities-why-msci-matters-in-a-greener-future" image="15" user_image="2" name="Team Inspire" title="Unveiling Sustainable Investment Opportunities: Why MSCI Matters in a Greener Future" description="The financial markets are a complex ecosystem, demanding reliable tools and insightful data for informed investment decisions. In this landscape, where sustainability is increasingly paramount, MSCI stands out as a vital partner for investors seeking to navigate the path towards a more sustainable future." />
                        <BlogSingleItem linkBlogDetails="/why-should-your-business-care-about-esg-transformation" image="14" user_image="2" name="Team Inspire" title="Why Should Your Business Care About ESG Transformation?" description="In today's world, consumers, investors, and employees are increasingly looking to businesses that prioritize not just profit, but also the environment, society, and responsible governance." />
                        <BlogSingleItem linkBlogDetails="/combating-climate-change-sustainable-businesses-can-be-heroes-too" image="13" user_image="2" name="Team Inspire" title="Combating Climate Change: Sustainable Businesses Can Be Heroes Too!" description="Recycling has long been heralded as the cornerstone of environmental sustainability. However, in the pursuit of a truly sustainable lifestyle, we must extend our efforts beyond recycling." />
                        <BlogSingleItem linkBlogDetails="/sustainable-supply-chains-building-resilience-and-transparency-in-the-business-world" image="12" user_image="2" name="Team Inspire" title="Sustainable Supply Chains: Building Resilience and Transparency in the Business World" description=" In today's interconnected world, our dependence on a vast network of suppliers is undeniable. But with this reliance comes a responsibility: ensuring our supply chains are not only efficient but also sustainable practices. Sustainable business practices don't just benefit the environment; they build resilience, enhance transparency, and contribute to long-term business success. " />
                        <BlogSingleItem linkBlogDetails="/embrace-sustainability-a-practical-guide-to-eco-friendly-living" image="11" user_image="2" name="Team Inspire" title="Embrace Sustainability: A Practical Guide to Eco-Friendly Living" description="Recycling has long been heralded as the cornerstone of environmental sustainability. However, in the pursuit of a truly sustainable lifestyle, we must extend our efforts beyond recycling." />
                        <BlogSingleItem linkBlogDetails="/a-pms-adventures-with-journey-module-of-myprism" image="10" user_image="2" name="Team Inspire" title="A PM’s Adventures with Journey Module of MyPrism" description="Hey there, fellow project managers! Sarah here, ready to spill the tea on my latest weapon in the battle against project chaos: the Journey Module." />
                        <BlogSingleItem linkBlogDetails="/myprism-symphony-orchestrating-success-in-a-complex-world-a-project-managers-journey" image="9" user_image="2" name="Team Inspire" title="MyPrism Symphony: Orchestrating Success in a Complex World - A Project Manager's Journey" description="Imagine your organization as a talented orchestra, brimming with potential. But without a conductor, the music descends into chaos. This was the scenario we faced in our organization—full of potential but lacking cohesion. That’s when we discovered MyPrism, the maestro that ensured every instrument—our people, processes, and data—played in perfect harmony." />
                        <BlogSingleItem linkBlogDetails="/myprism-action-from-chaotic-mind-to-mastermind" image="8" user_image="2" name="Team Inspire" title="MyPrism Action: From Chaotic Mind to Mastermind" description="Sarah stared at her overflowing to-do list, a mix of digital reminders and scribbled notes. Panic gnawed at her. Important tasks were getting lost, deadlines loomed, and the frequent context changes among apps made her feel disoriented/ she was feeling disoriented due to the frequent context changes between apps." />
                        <BlogSingleItem linkBlogDetails="/enhancing-business-resilience" image="7" user_image="2" name="Team Inspire" title="Enhancing Business Resilience: Strategies for Adapting to Climate Change" description="Climate change is a huge challenge, but it can be brought in line if governments, businesses, and individuals work together.”- Sir Richard Branson, Founder of Virgin Group" />
                        <BlogSingleItem linkBlogDetails="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024" image="6" user_image="2" name="Team Inspire" title="Sustainability Starts at Home: How to Live a Greener Life in 2024 " description="Understanding how our lifestyle decisions affect the environment and coming up with solutions to help everyone live better, lighter lives are key components of sustainable living." />
                        <BlogSingleItem linkBlogDetails="/my-ten-cents-on-leadership" image="1" user_image="1" name="Mrinmoy Chakraborty" title="My Ten Cents on Leadership" description="Head, Heart, and Spine – Three Critical Leadership Organs: Head (Competence), Heart (Compassion) and Spine (Character) are the building " />
                        
                        {/* <BlogSingleItem linkBlogDetails="/blogs-new" image="7" user_image="1" name="Mrinmoy Chakraborty" title="Entrepreneurship is Love" description="Entrepreneurship is like falling in" /> */}

                        {/* <BlogSingleItem image="3" user_image="2" name="Mrinmoy Chakraborty" title="The Importance of Instagram Metrics and Where to Find Them!" video_icon={<div className="blog__play p-absolute">
                           <button onClick={() => setIsVideoOpen(true)} data-fancybox> <i> <FiPlay/> </i></button>
                        </div>} /> */}

                        {/* <div className="blog__quote mb-50 p-relative white-bg fix" >
                           <div className="blog__quote-line">
                              <img className="line-1" src="assets/img/icon/blog/line-1.png" alt="" />
                              <img className="line-2" src="assets/img/icon/blog/line-2.png" alt="" />
                           </div>
                           <div className="quote mb-10">
                              <img src="assets/img/icon/blog/quote-1.png" alt="" />
                           </div>
                           <div className="quote-2 p-absolute">
                              <img src="assets/img/icon/blog/quote-2.png" alt="" />
                           </div>
                           <blockquote>
                              <p>Tosser argy-bargy mush loo at public school Elizabeth up the duff buggered chinwag on your
                                 bike mate don't get shirty with me super, Jeffrey bobby Richard cheesed off spend a penny
                                 a load of old tosh blag horse.</p>
                           </blockquote>
                           <h4>Shahnewaz Sakil</h4>
                        </div>

                        <BlogSingleItem image="4" user_image="2" name="Weir Doe" title="We craft marketing & digital products that grow businesses." />

                        <div className="blog__quote blog__quote-2 mb-50 p-relative white-bg fix"
                        >
                           <div className="blog__quote-line">
                              <img className="line-3" src="assets/img/icon/blog/line-3.png" alt="" />
                              <img className="line-4" src="assets/img/icon/blog/line-4.png" alt="" />
                           </div>
                           <div className="blog__link-icon">
                              <i className="icon_link_alt icon_1"></i>
                              <i className="icon_link_alt icon_2"></i>
                           </div>
                           <p>Oxford ruddy off his nut, bum bag is lavatory baking cakes bubble and squeak cheesed off what
                              a plonker.!</p>
                        </div>

                        <BlogSingleItem image="5" user_image="4" name="Indigo Violet" title="TikTok Influencer Marketing: How to Work With Influencers" />

                        <div className="basic-pagination" >
                           <ul>
                              <li>
                                 <Link to="/blogs">
                                    <i > <CgArrowLongLeft /> </i>
                                    <i > <CgArrowLongLeft /> </i>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/blogs">
                                    <span>1</span>
                                    <span>1</span>
                                 </Link>
                              </li>
                              <li className="active">
                                 <Link to="/blogs">
                                    <span>2</span>
                                    <span>2</span>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/blogs">
                                    <span>3</span>
                                    <span>3</span>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/blogs">
                                    <i ><IoEllipsisHorizontal/></i>
                                    <i ><IoEllipsisHorizontal /></i>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/blogs">
                                    <i > <CgArrowLongRight /> </i>
                                    <i > <CgArrowLongRight /> </i>
                                 </Link>
                              </li>
                           </ul>
                        </div> */}
                     </div>
                  </div>

                  <BlogLeftSideBar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogsArea;