import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";
import VideoSingleItem from "../../../components/VideoSingleItem/VideoSingleItem";

const AboutSustainabilityFour = ({ open, hanleUrl }) => {
  return (
    <>
      <section className="about__area pb-200 pt-100 black-bg-2 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              {
                <VideoSingleItem
                  image="4"
                  user_image="4"
                  title="ESG: The Winning Formula for Sustainable Business Success"
                  video_icon={
                    <div className="blog__play p-absolute">
                      <button
                        onClick={() => {
                          open(true);
                          hanleUrl();
                        }}
                        data-fancybox
                      >
                        {" "}
                        <i>
                          {" "}
                          <FiPlay />{" "}
                        </i>
                      </button>
                    </div>
                  }
                />
              }
              {/* <div className="about__thumb m-img">
                        <img src="assets/img/services/details/services-02.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div> */}
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  <h3 className="white-color">
                    ESG: The Winning Formula for Sustainable Business Success
                  </h3>
                </div>
                <p className="white-color">
                  For many years, we have been hearing the three-pronged formula
                  for sustainable business success- People, Planet and Profit.
                  We are aware of net-zero pledges by corporates and promises of
                  sustainably growing businesses. But addressing climate change
                  impacts requires businesses to adapt existing practices and
                  adopt new ones. The changes impact potentially every aspect of
                  a business and this makes the exercise very daunting, indeed.
                </p>
                <Link
                  to="/the-winning-formula-for-sustainable-business-success"
                  className="z-btn z-btn-border white-color"
                >
                  Learn more
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSustainabilityFour;
