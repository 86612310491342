import React from 'react';
import PressReleaseLeftSideBar from '../../PressRelease/PressReleaseArea/PressReleaseLeftSideBar';


// // all category
// const allCategory = ['see all', ...new Set(data.map(category => category.category))]
// // array unique items
// const arr = data;
// const uniqueItem = arr.filter((arr, index, self) =>
//    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PressReleaseArea = () => {
   // // active btn
   // const [active, setActive] = useState('see all');
   // const [filterPortfolio, setFilterPortfolio] = useState(uniqueItem);
   // const [values, setValues] = useState(4);

   // // filtering portfolio data
   // const filterCategory = (category) => {
   //    setActive(category)
   //    if (category === 'see all') {
   //       return setFilterPortfolio(uniqueItem)
   //    }
   //    const remainingItems = data.filter((item) => item.category === category);
   //    setFilterPortfolio(remainingItems)
   // }
   // // handleLoadMore
   // const handleLoadMore = () => {
   //    setValues((value) => value + 1)
   // }
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                           <h3>SOLiD’s journey in driving digital transformation</h3>
                           <p><i>In Association With Tata Communications</i></p>
                           <h4>How solid is driving business transformation in traditional industries with digital technologies</h4>
                           <p>It takes five to 10 years for an ‘overnight’ success,” says Mrinmoy Chakraborty, Vice President and Head of SOLiD Digital business at SOLiD Inc, Korea. “During my last 10 years in the internet of things (IoT) industry I have learned that, for any great technology business, we typically overestimate what will happen in the next year and underestimate what will happen in the next five.”</p>
                           <div className="blog__quote mb-40 p-relative white-bg fix">
                              <div className="blog__quote-line">
                                 <img className="line-1" src="assets/img/icon/blog/line-1.png" alt="" />
                                 <img className="line-2" src="assets/img/icon/blog/line-2.png" alt="" />
                              </div>
                              <div className="quote mb-10">
                                 <img src="assets/img/icon/blog/quote-1.png" alt="" />
                              </div>
                              <div className="quote-2 p-absolute">
                                 <img src="assets/img/icon/blog/quote-2.png" alt="" />
                              </div>
                              <blockquote>
                                 <p>Our future technology roadmap is geared more towards enterprise software suited for data-based services in large scale distributed environments</p>
                              </blockquote>
                              <h4>Mrinmoy Chakraborty</h4>
                           </div>
                           <p>SOLiD is a leader in providing wireless solutions offering flagship products DAS and WDM solutions, that improve indoor connectivity and coverage in high-demand and hard-to-reach locations. SOLiD Digital business works with traditional core industries helping to transform them with new, digital technolo- gies such as IoT, AI/ML, RPA and Blockchain.</p>
                           <p>Chakraborty believes that new technology should be embraced willingly by end users, stating that “even though new technology would bring disruptive change to business, it should bring gradual changes in the lives of people to make the change process natural and organic.”</p>
                           <p>Chakraborty has been at the company for over three years, but his journey here can be traced back to 2010, when he founded his first IoT startup after graduating from Stanford Business School. His engineering team and co-founding CEO were Korean, and he became fascinated by Korean technology and the Koreans’ exemplary work ethic. “I was excited by the opportunity to combine innovations in Korean hard- ware and Indian software for the global market,” he says. His startup introduced cellular technology into the pallet industry in the US market for the first time, which paved the way for customers’ digital transformation from a tradi- tional “supply chain company” to a “supply chain information engineering company”. However this technology was ahead of its time. Step forward a few years to 2016, when he met the SOLiD co-founders, Chairman and CEO Dr. Joon Chung and co-CEO Dr. Seung Hee Lee. “These two bril- liant visionaries built SOLiD as one of the global leaders in in-building wireless solution space. The following year, they entrusted me to build the IoT business for SOLiD – I saw this as my second chance to create a world class IoT organisation.”</p>
                           <p>His arrival at the organisation is all the more remarkable given that he’s the first foreign executive in SOLiD Korea’s 22-year history. “This journey started as an adventure with a lot of unknowns in a foreign land, but it’s also been the best one of my career so far,” he explains.</p>
                           <p>Chakraborty explains that SOLiD Digital has two main verticals: the “Internet of Moving Things (IoMT)”, which is essentially enterprise logis- tics, and the Industrial Internet of Things (IIoT), which relates to smart and safe factories. “In the IoMTspace, our services create action- able intelligence from returnable, reusable, high value assets such as containers, trailers, and chassis in cold chain and multi-modal logistics environments.</p>
                           <div className="blog__quote mb-40 p-relative white-bg fix">
                              <div className="blog__quote-line">
                                 <img className="line-1" src="assets/img/icon/blog/line-1.png" alt="" />
                                 <img className="line-2" src="assets/img/icon/blog/line-2.png" alt="" />
                              </div>
                              <div className="quote mb-10">
                                 <img src="assets/img/icon/blog/quote-1.png" alt="" />
                              </div>
                              <div className="quote-2 p-absolute">
                                 <img src="assets/img/icon/blog/quote-2.png" alt="" />
                              </div>
                              <blockquote>
                                 <p>I saw this as my second chance to create a world class IoT organisation</p>
                              </blockquote>
                              <h4>Mrinmoy Chakraborty</h4>
                           </div>
                           <p>“In IIoT, our focus is on offering industrial safety, asset monitoring and predictive maintenance as a service. My team’s priority is to offer solutions that are technically feasible, economi- cally viable, massively scalable and offer an exceptional end-user experi- ence that hides the complexity of technology from that user.”</p>
                           <p>Chakraborty’s priority is to make SOLiD the preferred “internet of value” partner for enterprises around the world, a term he prefers to IoT, believing that the hero should be the business rather than the tech- nology itself.</p>
                           <p>To this end, the company’s technology roadmap is market driven and built for solving complex highimpact customer problems. For instance, in 2018 it primarily focused on its hardware platform on LPWAN (low-power wide-area network technologies), whereas in the last year it has evolved as a total solution provider, focusing more on end application software-driven services. “Some of the areas where we are building next generation solutions and co-innovating with partners are in seamless universal tracking in indoor and outdoor environments,” Chakraborty says. “We’re using hybrid sensor technologies such as WiFi, Bluetooth Low Energy (BLE), Ultra Wide Band (UWB) and GPS augmented positioning technologies.</p>
                           <p>“Our future technology roadmap is geared more towards enterprise soft- ware suited for data based-services in large scale distributed environments. Edge intelligent solutions for 5G or private LTE environments will be some of our core future offerings.”</p>
                           <p>In the last three years SOLiD has worked closely with several industry leading customers, mostly with $1bn+ revenues, to help them launch their early or even first digital transforma- tion initiatives. One of these was a leading Korean bank, for which the company helped launch asset monitor- ing services for managing collaterals.</p>
                           <p>This resulted in over 360% RoI over a period of nine months. In areas of safety and compliance, last year they conducted proof of concepts (POCs) in one of the largest factories in Korea. “We intend to take this service to prouction this year and it’s expected to e one of the world’s largest industrial afety services,” he says.</p>
                           <p>The benefits to transforming the traditional industries the company works with using digital technologies include operational efficiency, safety and compliance, and productivity. “Some of our new customers are also looking at servitisation, or creating new service revenue business models. Once the customers see the value of digitalisation in areas of cost saving, productivity and safety, I believe they will gain more confidence and look at IoT as their new revenue earner. We have started seeing this happening, and expect to see more in the next two to three years.”</p>
                           <div className="blog__quote mb-40 p-relative white-bg fix">
                              <div className="blog__quote-line">
                                 <img className="line-1" src="assets/img/icon/blog/line-1.png" alt="" />
                                 <img className="line-2" src="assets/img/icon/blog/line-2.png" alt="" />
                              </div>
                              <div className="quote mb-10">
                                 <img src="assets/img/icon/blog/quote-1.png" alt="" />
                              </div>
                              <div className="quote-2 p-absolute">
                                 <img src="assets/img/icon/blog/quote-2.png" alt="" />
                              </div>
                              <blockquote>
                                 <p>Every IoT service is a data-based service, and applying AI/ML can make each service more responsive, predictive and proactive
                                 </p>
                              </blockquote>
                              <h4>Mrinmoy Chakraborty</h4>
                           </div>
                           <p>AI and ML are also key to transforming customer operations. “In our business, these technologies have significant implications in predictive maintenance, location positioning, edge intelligence and also in areas of industrial safety for predictive situational awareness solutions and in building a more resilient supply chain. Every IoT service is a data- based service, and applying AI/ML can make each service more responsive, predictive and proactive.</p>
                           <p>“At the same time, we are conscious of not force fitting technologies just because they’re cool and trendy. Our approach is to find appropriate technologies that create maximum impact for our customers.”</p>
                           <p>Chakraborty explains that manag- ing this digital change must be done with a human-centric approach. “Any new technology introduction is change management,” he says. “It changes people’s lives and even people’s vocabulary. Digital tech- nologies make data transparent, and at times eliminate middlemen, which can be scary for people that are part of the ecosystem.”</p>
                           <p>Looking ahead, he sees an expansion in the company’s services. “In the past three years we have created a track record primarily in the Korean market. In the next few years, I see more large scale business expansion globally, in South east Asia, the UK and the US. Also, our services will be more data intensive and more suitable for the 5G world.”</p>
                           <p>For many in the technology industry, the COVID-19 pandemic has fast- tracked digitalisation. Chakraborty is optimistic. “I have a very positive outlook for the post-Coronavirus world, especially for my friends and partners working in technology industries. I believe that digital transformation was never a question of ‘if’, it has always been a question of when. COVID-19 has decided one thing for us – the time is now.”</p>
                        </div>
                     </div>
                  </div>

                  <PressReleaseLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default PressReleaseArea;