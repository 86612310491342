import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyLeftSideBar from '../../CaseStudies/CaseStudiesArea/CaseStudyLeftSideBar';

const CaseStudyArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                     {/* <div className="blog__text mb-45">
                           <h4>Overview</h4>
                        </div> */}
                        <div className="blog__list mb-45">
                           <p>
                              <b>A leading modular construction company with 2,000 employees in the Middle East, Africa, and Asia faced operational and digital maturity challenges due to outdated technology and inefficient processes.</b>
                           </p>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Challenges</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Decentralized IT Initiatives:</b> Inefficient, isolated digital projects.</li>
                              <li><b>Project Delays:</b> Launches delayed by up to three years.</li>
                              <li><b>Heterogeneous Systems:</b> Multiple systems for similar functions.</li>
                              <li><b>Integration Issues:</b> Siloed systems lack data sharing.</li>
                              <li><b>Legacy Technology:</b> Outdated and unsupported tech.</li>
                              <li><b>Manual Processes:</b> Inefficient, labor-intensive operations.</li>
                              <li><b>Opportunity Loss:</b> No mechanisms for data monetization.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Solutions</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Corporate Vision:</b>Established a five-year transformation plan.</li>
                              <li><b>Best Practices:</b>Developed nine practices for 135 subsidiaries.</li>
                              <li><b>Operating Models:</b>Created models for effective implementation.</li>
                              <li><b>KPIs</b>Defined metrics to measure progress.</li>
                              <li><b>Digitalization Strategy:</b>Automated business functions.</li>
                              <li><b>Technology Assessments:</b>Evaluated tech for future mergers.</li>
                              
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Business Value</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Increased Maturity: </b>Digital and cybersecurity maturity improved from below 1 to over 2 within 1.5 years.</li>
                              <li><b>Consistent Practices: </b>Established uniform business policies.</li>
                              <li><b>Revenue Growth:</b>Enhanced strategies for increased profitability.</li>
                              <li><b>Operational Efficiency:</b>Streamlined processes and reduced manual labor.</li>
                              
                           </ul>
                        </div>
                        
                        <div>                           
                           <Link to="/assets/case-studies/solid-inspire-case-study-construction.pdf" target='_blank' className='card download-casestudy stretched-link'>
                              <p className='mb-0'>Download <br /> Construction Industy<br /> Case Stady</p>
                              <img src="/assets/img/icon/download-pdf.png" className='ml-30 p-2 img-fluid' alt="" />                           
                           </Link>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <CaseStudyLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default CaseStudyArea;