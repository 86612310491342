import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';

const BlogDetails = () => {
   return (
      <>
         <PageHelmet pageTitle="21 Things I learned in 2021" />

         <Header/>
         <CommonPageHeader title="21 Things I learned in 2021" subtitle="Blog Details" />
         <BlogDetailsArea/>
         <Footer/>
      </>
   );
};

export default BlogDetails;