import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import useGlobalContext from '../../hooks/useGlobalContext';
import { FaHome } from 'react-icons/fa';

const Header = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="header__area p-relative header__transparent">
               <div id="header__sticky" className={stickyMenu ? 'sticky header__bottom header__bottom-2' : 'header__bottom header__bottom-2'}>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                           <div className="logo">
                              <NavLink to="/" title='Solid Inspire Home'>
                                 <img src="assets/img/logo/solid-inspire-logo-white.svg" alt="Solid Inspire Logo" />
                              </NavLink>
                           </div>
                           <div className="logo-gradient">
                              <NavLink to="/" title='Solid Inspire Home'>
                                 <img src="assets/img/logo/solid-inspire-logo.svg" alt="Solid Inspire Logo" />
                              </NavLink>
                           </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                           <div className="header__bottom-right d-flex justify-content-end align-items-center">
                              <div className="main-menu menu_wrapper_one">
                                 <nav id="mobile-menu">
                                    <ul>
                                       <li>
                                          <NavLink to="/" className="text-24px"> {<FaHome />}</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to="/about">We</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/about">About Us</NavLink></li>
                                             <li><NavLink to="/team">Leadership</NavLink></li>
                                             <li><NavLink to="/careers">Careers</NavLink></li>
                                          </ul>
                                       </li>
                                       <li>
                                          <NavLink to="/services">Create</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/enterprise-architecture">Enterprise Architecture</NavLink></li>                                            
                                             <li><NavLink to="/information-security">Information Security</NavLink></li>
                                             <li><NavLink to="/platform-development">Platform Development</NavLink></li>
                                             <li><NavLink to="/internet-of-value">Internet of Value</NavLink></li>
                                             <li><NavLink to="/esg-transformation">ESG Transformation</NavLink></li>
                                          </ul>
                                       </li>
                                       <li>
                                          <NavLink to="/esg-transformation">Sustainable</NavLink>
                                          <ul className="submenu">                                             
                                             <li><NavLink to="/about-sustainability">About Sustainability</NavLink></li>
                                          </ul>
                                       </li>
                                       <li>
                                          <NavLink to="/blogs">Digital</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/blogs">Blog</NavLink></li>
                                             <li><NavLink to="/press-release">Media</NavLink></li>
                                             <li><NavLink to="/interviews">Interviews</NavLink></li>
                                             
                                          </ul>
                                       </li>
                                      
                                       <li>
                                          <NavLink to="/enterprises">Champions</NavLink>
                                          {/* <ul className="submenu">
                                             <li><NavLink to="/portfolio">Industries</NavLink></li>
                                             <li><NavLink to="/case-studies">Case Studies</NavLink></li>
                                          </ul> */}
                                       </li>
                                       <li><NavLink to="/contact" className="contact">Interested?</NavLink></li>
                                    </ul>
                                 </nav>
                              </div>
                              <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                 <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>


         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default Header;