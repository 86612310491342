import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";
import VideoSingleItem from "../../../components/VideoSingleItem/VideoSingleItem";

const SustainabilityAboutOne = ({ open, hanleUrl }) => {
  return (
    <>
      <section className="capabilities__area p-relative pt-180 pb-155 fix">
        {/* <div className="capabilities__thumb p-absolute" style={{ background:`url(assets/img/capabilities/capabilities-img.jpg)`
               , backgroundPosition: 'center', backgroundSize:'cover'}}></div>
            <div className="capabilities__shape p-absolute wow fadeInLeft" >
               <img src="assets/img/capabilities/capabilities-shape.png" alt="shape"/>
            </div> */}
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              <div
                className="capabilities__content wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="section__title section__title-2 mb-60">
                  <h3>
                    Future-proof your Business: Embrace Climate Change,
                    Embrace Opportunity
                  </h3>
                </div>
                <div className="capabilities__content">
                  <p>
                    It is no secret that climate change is a global crisis
                    caused by human activities. Businesses are a significant
                    contributor to the emission of greenhouse gases, through the
                    supply chains, energy consumption, operations, etc.{" "}
                  </p>
                  {/* <p className='white-color'>In recent years climate change awareness and activism have reached new heights with the 2015 Paris Agreement setting climate targets for 2030, the need for actions to reduce the impacts and risks of climate change has become paramount.</p> */}
                  {/* <p className='white-color'>Besides being important contributors to climate change, every business, in turn, will also be impacted by the effects, directly or indirectly. These effects include water scarcity, supply chain shortages, extreme weather events, detrimental changes to their markets, cost of operations, investor trust, and product quality & services</p> */}
                </div>
                <Link
                  to="/future-proof-your-business"
                  className="z-btn"
                >
                  Learn more
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              {
                <VideoSingleItem
                  image="1"
                  user_image="1"
                  title="Future-proof your Business: Embrace Climate Change, Embrace Opportunity"
                  video_icon={
                    <div className="blog__play p-absolute">
                      <button
                        onClick={() => {
                          open(true);
                          hanleUrl();
                        }}
                        data-fancybox
                      >
                        {" "}
                        <i>
                          {" "}
                          <FiPlay />{" "}
                        </i>
                      </button>
                    </div>
                  }
                />
              }
              {/* <div className="about__thumb m-img">
                        <img src="assets/img/services/details/services-02.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SustainabilityAboutOne;
