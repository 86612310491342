import React from 'react';

const ContactArea = () => {
   return (
      <>
         <section className="contact__area">
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        <iframe title='Solid Inspire Office Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.5026070687536!2d127.10624175087894!3d37.40159354121578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca78c6218a635%3A0x1c37e5aa294ec91!2sPangyo%20Techno%20Valley!5e0!3m2!1sen!2sin!4v1652460045990!5m2!1sen!2sin"></iframe>
                        <div className="contact__wrapper d-md-flex justify-content-between wow fadeInUp" data-wow-delay=".3s">
                           <div className="contact__info mr-100">
                              <h3>Our Coordinates</h3>
                              <ul>
                                 <li>
                                    <h4>Address</h4>
                                       <p className='mt-20'><b>SOLiD Inspire</b>,<br />
                                       7th Floor, SOLiD Space,<br />
                                       220 Pangyoyeok-ro, Bundang-gu,<br />
                                       Seongnam-si, Gyeonggi-do, 13493,<br />
                                       Rep. of Korea
                                       </p>


                                 </li>
                                 <li>
                                    <table>
                                       <tr>
                                          <td style={{width:'100px'}}>Latitude:</td>
                                          <td>37.4016° N</td>
                                       </tr>
                                       <tr>
                                          <td>Longitude:</td>
                                          <td>127.1084° E</td>
                                       </tr>
                                       
                                    </table>
                                 </li>
                                 <li>
                                 <div className='alert alert-info mt-3 text-center'>
                              Attitude: <br />
                               <b>180 Degrees from the rest of the world</b>
                              </div>
                                 </li>
                              </ul>
                           </div>
                           <div className="contact__form">
                              <form >
                                 <input type="text" required placeholder="Your Name" />
                                 <input type="email" required placeholder="Your Email" />
                                 <textarea required placeholder="Your Message"></textarea>
                                 <button type="submit" className="z-btn">Send Message</button>
                              </form>
                              <div className='mt-40'>
                              <p><strong>Email Address</strong> <br />
                               <a href="mailto:ask@solidinspire.com">ask@solidinspire.com</a></p>
                              </div>                             
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section >
      </>
   );
};

export default ContactArea;