import React from 'react';

const SingleCareerRule = ({icon,title}) => {
   return (
      <>
         <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div className="services__item mb-50 grey-bg-18 p-4 rounded-5">
               <div className="services__icon mb-25">
                  <img src={`assets/img/icon/career/career-${icon}.png`} alt="Rule"/>
               </div>
               <div className="services__content mh-50">
                  <h5>{title}</h5>
               </div>
            </div>
         </div>
      </>
   );
};

export default SingleCareerRule;