import React from 'react';
import { Link } from 'react-router-dom';

const SinglePressRelease = ({ title, titleSub, prLink, image, byName }) => {
   return (
      <>
         <div className="col-xl-4 col-lg-4 col-md-4">
            <div className="card mb-30">
            <img className="card-img-top" src={`assets/img/pressrelease/pressrelease-${image}.jpg`} alt="Media"/>
               <div className="card-body">
                  <h6 className="card-subtitle text-muted mb-10 text-uppercase">{titleSub}</h6>
                  <h5 className="card-title mb-20" style={{height:'50px'}}>{title}</h5>
                  <p>{byName}</p>              
                  <Link to={`${prLink}`} className="btn btn-primary">More details</Link>
               </div>
            </div>
         </div>
      </>
   );
};

export default SinglePressRelease;