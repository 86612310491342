import React from 'react';
import { Link } from 'react-router-dom';

const PressReleaseLeftSideBar = () => {
    return (
        <>
            <div className="col-xl-4 col-lg-4">
                <div className="blog__sidebar">
                    {/* <div className="sidebar__widget mb-50 ">
                        <div className="sidebar__widget-content">
                            <div className="search">
                                <form action="#">
                                    <input type="text" placeholder="Search..." />
                                    <button type="button"><i > <FaSearch /> </i></button>
                                </form>
                            </div>
                        </div>
                    </div> */}
                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Recent Press Release</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc-post">
                                <ul>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/we-will-never-goback-to-the-old-normal-transform-your-business-now-or-you-might-vanish"><img src="assets/img/PressRelease/rc/rc-1.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/we-will-never-goback-to-the-old-normal-transform-your-business-now-or-you-might-vanish">'We Will Never Go Back to the Old Normal: Transform Your Business Now Or You Might Vanish'</Link></h6>
                                            {/* <div className="rc-meta"><span>Jan 01, 2022</span> </div> */}
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/cxbuzz-interview-with-mrinmoy-chakraborty-head-of-digital-transformation-business-at-solid-technologies"><img src="assets/img/PressRelease/rc/rc-2.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/cxbuzz-interview-with-mrinmoy-chakraborty-head-of-digital-transformation-business-at-solid-technologies">CXBuzz Interview with Mrinmoy Chakraborty, Head of Digital Transformation Business at SOLiD Technologies</Link></h6>
                                            {/* <div className="rc-meta"><span>Jan 01, 2022</span> </div> */}
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/cxo-deep-dive-mrinmoy-chakraborty"><img src="assets/img/PressRelease/rc/rc-3.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/cxo-deep-dive-mrinmoy-chakraborty">CXO Deep Dive | Mrinmoy Chakraborty</Link></h6>
                                            {/* <div className="rc-meta"><span>Jan 01, 2022</span> </div> */}
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Services</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="cat-link">
                                <ul>
                                    <li><Link to="/enterprise-architecture">Enterprise Architecture</Link></li>
                                    <li><Link to="/information-security">Information Security</Link></li>
                                    <li><Link to="/platform-development">Platform Development</Link></li>
                                    <li><Link to="/internet-of-value">Internet of Value</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default PressReleaseLeftSideBar;