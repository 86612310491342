import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import ContactArea from './ContactArea/ContactArea';

const Contact = () => {
   return (
      <>
         <PageHelmet pageTitle="Contact Us" />

         <Header/>
         <CommonPageHeader title="Contact Us" subtitle="Get in touch" />
         {/* <ContactInfoArea/> */}
         <ContactArea/>
         <Footer/>
      </>
   );
};

export default Contact;