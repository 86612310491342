import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';

const BlogDetails11 = () => {
   return (
      <>
         <PageHelmet pageTitle="Embrace Sustainability: A Practical Guide to Eco-Friendly Living" />

         <Header/>
         <CommonPageHeader title="Embrace Sustainability: A Practical Guide to Eco-Friendly Living" subtitle="Blog Details" />
         <BlogDetailsArea/>
         <Footer/>
      </>
   );
};

export default BlogDetails11;