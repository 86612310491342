import React from 'react';
// import SingleCareerRule from '../../../components/SingleCareerRule/SingleCareerRule';

const InterviewsArea = () => {
   return (
      <>
         <section className="about__area pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 mb-85 text-center">
                        <h2>Coming Soon</h2>
                     </div>
                  </div>
               </div>
               {/* <div className='row'>
                  <div className="col-xl-12">
                     <div className="row">
                        <SingleCareerRule icon="6" title="Integrity is always on top of our list." />
                        <SingleCareerRule icon="1" title="Hiring talents bigger than us." />
                        <SingleCareerRule icon="2" title="We value creativity and imagination more than anything else." />
                        <SingleCareerRule icon="3" title="Welcoming professionals who are capable and humble." />
                        <SingleCareerRule icon="4" title="The hunt is for those who believe that everyone can win. " />
                        <SingleCareerRule icon="5" title="Eye for details is what we look for." />
                        <SingleCareerRule icon="7" title="Hiring for the future." />
                        <SingleCareerRule icon="8" title="Putting self-respect first." />
                        <SingleCareerRule icon="9" title="Growing by celebrating teammates’ success." />
                     </div>
                  </div>
               </div> */}

            </div>
         </section>
      </>
   );
};

export default InterviewsArea;