import React from 'react';
import BlogLeftSideBar from '../../Blogs/BlogsArea/BlogLeftSideBar';

const BlogDetailsArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Head, Heart, and Spine </b>– Three Critical Leadership Organs: Head (Competence), Heart (Compassion) and Spine (Character) are the building blocks of leadership. You need a Head to think and analyze data and complex situations in order to make logical decisions. You need a Heart to “listen” (not hear) and understand other person’s point of view. You can win in relationships, both professional and personal only when you have developed the capability to think like the person you want to win over. You need a Spine to stand for what you believe in. If you do not stand for Something you tend to fall for Everything.</li>
                              <li><b>Ask Both “Why” and “Why Not” Questions:</b> It is important to wear dual hats of a manager and a leader in a corporate environment. As manager, it pays to be paranoid in order to assess risks of business environment and manage the details. Hence you need to ask a lot of “Why” questions to get into the heart of situations and execute flawlessly. As a leader, you need to create a vision for the team and constantly challenge them to a higher level of performance by asking “Why Not”?</li>
                              <li><b>Take Accountability for Problems:</b> Success has many fathers, but failure is, more often, orphan. However, both success and failure definitions are merely perceptions of individuals and both can only be judged by the impartial verdict of Time. True leaders take a little less share of success and little more share of failures than they actually deserve. Why don’t you challenge yourself by adopting a “perceived failure situation” and try making a difference? Remember, the quality of your life is defined by the problem you choose to solve in your life.</li>
                              <li><b>Empower Yourself:</b> Power is the freedom you choose to give yourself to do the right things in life. The power you derive from your titles is transient and should not be relied upon. Real power comes from knowledge and the ability to take responsibility and accountability for your actions. Hence Power is earned, and not derived. Only You can empower Yourself, nobody else can. Power is not a means to satisfy one’s ego. Power should make you humble and responsible. If it does not, you are no different from the infant obsessed with the newest toy (power). Toy breaks and so does power, if not handled carefully.</li>
                              <li><b>Develop Healthy Respect for Yourself, Team and the Organization:</b> Some people have so much respect for their bosses that they have very little left for themselves. But leaders must have healthy self‐respect to stand by their beliefs. You can only develop passion at work and become successful, when you genuinely respect the core‐values of your team and that of the organization.</li>
                              <li><b>Learn to Defocus:</b> To focus on the “real job”, it is important to defocus from the non‐value adding, low impact, seemingly urgent (but not important) tasks. Motion without action is more dangerous than complete inaction. Do not get into the trap when you artificially make yourself too busy to take care of important things such as customer and people issues.</li>
                              <li><b>80‐20 Rule of Focus:</b> The ROI of playing to your strength is more than focusing too much on your weaknesses. Typically, one should spend no more than 20% of his/her time on the weaknesses and focus 80% of time in developing on one’s strengths. Given the limited bandwidth of life, why spend time on your weaknesses alone and become average when you have the option of strengthening your strengths and achieve excellence by doing what you love? However, one must correct one’s flaws to an extent where those do not become fatal. You can develop a team with complementary personal competencies so that the team completes each other.</li>
                              <li><b>Tell the Truth:</b> The ROI of honesty is undeniably immense. Dishonesty is much worse than incompetency because the latter is a correctable evil. If you are honest, you have to remember fewer things. Honesty will give the confidence to face any challenges at life and work. Moreover, the Truth “comes out of the closet” anyway. Resisting truth is delaying the eventuality artificially, ineffectively. Credibility is No 1 quality of a good leader. Credibility is earned by telling the truth, every time, no matter what the context and cost is.</li>
                              <li><b>Remember Names, Stories and Return Gestures:</b> Develop the habit remembering people’s names and the story you and him have created together. These little experiences, significant and apparently insignificant ones, added overall, make your life. If you want to be remembered, remember others. Give something back, in your own way, when your turn comes.</li>
                              <li><b>Be Passionate and Dispassionate Simultaneously:</b> Too much passion, at times clouds your vision from truth. As a leader, you need to make unbiased and objective decisions based on logic, data and intuition. You can do that only when you dissociate yourself from the problem and treat yourself as an “outsider”. Sometimes, you need to remind yourself that you are merely a custodian of your title/job and not the owner. Do not let your title define who you are.</li>
                              
                           </ul>
                        </div>
                        <div className="blog__text mb-40">
                        </div>

                       
                     </div>
                  </div>

                  <BlogLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;