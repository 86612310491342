import React from 'react';
import { Link } from 'react-router-dom';

const CaseStudyLeftSideBar = () => {
    return (
        <>
            <div className="col-xl-4 col-lg-4">
                <div className="blog__sidebar">
                    {/* <div className="sidebar__widget mb-50 ">
                        <div className="sidebar__widget-content">
                            <div className="search">
                                <form action="#">
                                    <input type="text" placeholder="Search..." />
                                    <button type="button"><i > <FaSearch /> </i></button>
                                </form>
                            </div>
                        </div>
                    </div> */}
                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Case Studies</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc-post">
                                <ul>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/banking"><img src="assets/img/industry/rc/rc-banking.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/banking">Banking Industry</Link></h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/retail"><img src="assets/img/industry/rc/rc-retail.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/retail">Food and Retail</Link></h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/healthcare"><img src="assets/img/industry/rc/rc-healthcare.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/healthcare">Healthcare Industry</Link></h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/energy"><img src="assets/img/industry/rc/rc-energy.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/energy">Energy Industry</Link></h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/airline"><img src="assets/img/industry/rc/rc-airline.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/airline">Airline Industry</Link></h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/automotive"><img src="assets/img/industry/rc/rc-automotive.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/automotive">Automotive Industry</Link></h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/construction"><img src="assets/img/industry/rc/rc-construction.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/construction">Construction Industry</Link></h6>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Services</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="cat-link">
                                <ul>
                                    <li><Link to="/enterprise-architecture">Enterprise Architecture</Link></li>
                                    <li><Link to="/information-security">Information Security</Link></li>
                                    <li><Link to="/platform-development">Platform Development</Link></li>
                                    <li><Link to="/internet-of-value">Internet of Value</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default CaseStudyLeftSideBar;