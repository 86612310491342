import { CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';

const ServicesTwo = () => {
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/services/details/services-02.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h2>Information Security as a Service</h2>
                        </div>
                        <p>Protecting every business from any data-related vulnerabilities is one of the key priorities for the business, especially in the new normal world. The urgency of this service is also driven by the compliance and Governance laws in recent times We create and implement security systems and solutions to safeguard your software systems, networks, and data centers from unauthorized access, disclosure, use/misuse, destruction, modification, or disruption.</p>
                        <Link to="/information-security" className="z-btn " >Learn more<i > <CgArrowLongRight /> </i></Link> 
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesTwo;