import React from 'react';
import SinglePressRelease from '../../../components/SinglePressRelease/SinglePressRelease';
import { Link } from 'react-router-dom';

// all category
// const allCategory = ['see all', ...new Set(data.map(category => category.category))]
// // array unique items
// const arr = data;
// const uniqueItem = arr.filter((arr, index, self) =>
//    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PressReleaseArea = () => {
   // // active btn
   // const [active, setActive] = useState('see all');
   // const [filterPortfolio, setFilterPortfolio] = useState(uniqueItem);
   // const [values, setValues] = useState(4);

   // // filtering portfolio data
   // const filterCategory = (category) => {
   //    setActive(category)
   //    if (category === 'see all') {
   //       return setFilterPortfolio(uniqueItem)
   //    }
   //    const remainingItems = data.filter((item) => item.category === category);
   //    setFilterPortfolio(remainingItems)
   // }
   // // handleLoadMore
   // const handleLoadMore = () => {
   //    setValues((value) => value + 1)
   // }
   return (
      <>
         <section className="portfolio__area pt-110 pb-160">
            <div className="container">
               <div className="row">
                  <SinglePressRelease prLink="/we-will-never-goback-to-the-old-normal-transform-your-business-now-or-you-might-vanish" image="1" titleSub="INTERVIEW 01" byName="By David Sehyeon Baek" title="We Will Never Go Back to the Old Normal: Transform Your Business Now Or You Might Vanish" />
                  <SinglePressRelease prLink="/cxbuzz-interview-with-mrinmoy-chakraborty-head-of-digital-transformation-business-at-solid-technologies" image="2" titleSub="INTERVIEW 02" byName="By Efrat Vulfsons" title="CXBuzz Interview with Mrinmoy Chakraborty, Head of Digital Transformation Business at SOLiD Technologies" />
                  <SinglePressRelease prLink="/cxo-deep-dive-mrinmoy-chakraborty" image="3" titleSub="INTERVIEW 03" byName="By Mrinmoy Chakraborty" title="CXO Deep Dive | Mrinmoy Chakraborty" />
                  <div className="col-xl-4 col-lg-4 col-md-4">
                     <div className="card mb-30">
                        <div>
                           <iframe width="100%" height="240" src="https://www.youtube.com/embed/aCQGajlTW4A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="card-body">
                           <h6 className="card-subtitle text-muted mb-10 text-uppercase">INTERVIEW 04</h6>
                           <h5 className="card-title mb-20" style={{ height: '50px' }}>We'll never go back to normal: Transform your business now! | Productivity Talk</h5>
                           <p>Asian Productivity Organization</p>
                           <Link to="https://www.youtube.com/watch?v=aCQGajlTW4A" className="btn btn-primary">More details</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default PressReleaseArea;