import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import SustainabilityESG01DetailsArea from './SustainabilityESG01DetailsArea/SustainabilityESG01DetailsArea';
import SustainabilityESG01Header from './SustainabilityESG01Header/SustainabilityESG01Header';

const SustainabilityESG01 = () => {
   return (
      <>
         <PageHelmet pageTitle="Future-proof your Business: Embrace Climate Change, Embrace Opportunity" />

         <Header/>
         <SustainabilityESG01Header />
         <SustainabilityESG01DetailsArea />
         <Footer />
      </>
   );
};

export default SustainabilityESG01;