import React from 'react';
import SinglePrice from '../../../components/SingleJobPost/SingleJobPost';

const JobPost = () => {
   return (
      <>
         <section className="price__area pt-100 pb-100 grey-bg-18">
            <div className="container">
               <div className="row">

                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 mb-85 text-center">
                        <h2>Open positions</h2>
                        <p>Together let's deliver nothing but “WOW”.</p>
                     </div>
                  </div>
               </div>

               <div className="price__tab-content">

                  <div className="row">
                     <SinglePrice title="EPM Implementation Expert" list1="Location: 1 year onsite and later remote" list2="Job type: Full time" list3="Experience: 6 – 10 years" />
                     <SinglePrice title="PM for EPM Implementation" list1="Location: 1 year onsite and later remote" list2="Job type: Full time" list3="Experience: 6 to 8 years" />
                     <SinglePrice title="Nodejs" list1="Location: Remote" list2="Job type: Full time" list3="Experience: Mid-level" />
                     <SinglePrice title="ReactNative Developer" list1="Location: Remote" list2="Job type: Full time" list3="Experience: 5-10 years" />
                     <SinglePrice title="Angular Developer" list1="Location: Remote" list2="Job type: Full time" list3="Experience: 5-10 years" />

                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default JobPost;