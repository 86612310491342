import React from 'react';
import { CgArrowLongRight } from 'react-icons/cg';

const HomeMyPrismSingleFeature = ({image,title, description, linkService}) => {
   return (
      <>
      <a href='https://myprism.world/' className="features__item features__item-2 white-bg fix mb-30" target='_blank'>
         {/* <Link  to={{ pathname: `${linkService}` }} className="features__item features__item-2 white-bg fix mb-30"> */}

            <div className="features__thumb-2" style={{ background: `url(assets/img/features/02/features-1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover'}} ></div>
            <div className="features__content-2">
               <div className="features__icon features__icon-2">
               <img src={`assets/img/icon/myprism/${image}.png`} alt="" />
               {/* <img src={`assets/img/icon/myprism/myprism-${image}.png`} alt="" /> */}
               </div>
               <h3>{title}</h3>
               <p>{description}</p>
               <div className="features__btn-2">
                  <a href='https://myprism.world/'  target='_blank' className="link-btn">
                  <i > <CgArrowLongRight /> </i>
                     <i > <CgArrowLongRight /> </i>
                  </a>
                  {/* <Link to={`${linkService}`} className="link-btn">
                     <i > <CgArrowLongRight /> </i>
                     <i > <CgArrowLongRight /> </i>
                  </Link> */}
               </div>
            </div>
         </a>
      </>
   );
};

export default HomeMyPrismSingleFeature;