import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import CaseStudyArea from './CaseStudyArea/CaseStudyArea';

const CaseStudy05Airline = () => {
   return (
      <>
         <PageHelmet pageTitle="Transforming Asset Management in Logistics | Airline Industry" />
         <Header/>
         <CommonPageHeader title="Transforming Asset Management in Logistics" subtitle="Case Studies" />
         <CaseStudyArea/>
         <Footer/>
      </>
   );
};

export default CaseStudy05Airline;