import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import ServicesOurServices from './ServicesOurServices/ServicesOurServices';
import ServicesOne from './ServicesOne/ServicesOne';
import ServicesTwo from './ServicesTwo/ServicesTwo';
import ServicesThree from './ServicesThree/ServicesThree';
import ServicesFour from './ServicesFour/ServicesFour';

const Services = () => {
   return (
      <>
         <PageHelmet pageTitle="Services" />
         <Header/>
         <CommonPageHeader title="Services" subtitle="Services" />
         <ServicesOurServices />
         <ServicesOne />         
         <ServicesTwo />
         <ServicesThree />
         <ServicesFour />
         <CommonCtaArea title="It’s high-time you connect, integrate and strengthen your business functions" linkText="Start Now" />
         <Footer/>
      </>
   );
};

export default Services;