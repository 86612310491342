import React from 'react';
import PressReleaseLeftSideBar from '../../PressRelease/PressReleaseArea/PressReleaseLeftSideBar';

// // all category
// const allCategory = ['see all', ...new Set(data.map(category => category.category))]
// // array unique items
// const arr = data;
// const uniqueItem = arr.filter((arr, index, self) =>
//    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PressReleaseArea = () => {
   // active btn
   // const [active, setActive] = useState('see all');
   // const [filterPortfolio, setFilterPortfolio] = useState(uniqueItem);
   // const [values, setValues] = useState(4);

   // // filtering portfolio data
   // const filterCategory = (category) => {
   //    setActive(category)
   //    if (category === 'see all') {
   //       return setFilterPortfolio(uniqueItem)
   //    }
   //    const remainingItems = data.filter((item) => item.category === category);
   //    setFilterPortfolio(remainingItems)
   // }
   // // handleLoadMore
   // const handleLoadMore = () => {
   //    setValues((value) => value + 1)
   // }
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                           <h1>CXO Deep Dive | Mrinmoy Chakraborty</h1>
                           <p><b>What do you think are the biggest challenges organisations face in the adoption of AI?</b></p>
                           <p>Lack of knowledge/expertise to use AI</p>
                           <p>Stakeholder resistance</p>
                           <p><b>What will be the next evolution of advanced tech that we can expect in the coming years?</b></p>
                           <p>Machine Learning / Artificial Intelligence</p>
                           <p>IoT</p>
                           <p>5G/6G</p>
                           <p><b>For those who haven’t fully embraced the digital world, is it fundamental to future success?</b></p>
                           <p>Yes, you have to go digital one way or another if you want to survive.</p>
                           <p><b>How is your industry preparing for an AI-Centric world?</b></p>
                           <p>Digital and Data are essentially two sides of the same coin. The success of every digital transformation project depends on how effectively data is collected across the business processes, along the entire consumer journey and analysed to create actionable insights. SOLiD Inspire primarily focuses on transforming traditional large enterprises in automotive, food & retail as well as the new age energy transition companies such as solar. We see AI-based use cases being deployed in real-time dynamic pricing, personalised product recommendations, chatbots in customer service functions, smart vehicle inspections and even doing remote site surveys in solar installations. The use cases are only limited by our imaginations as the potential is immense. It is heartening to see that several of these use cases are generating tangible business value already. Currently, AI/ML tools are more of a necessity than a novelty. Customers are using AI for both incremental as well as disruptive innovations across the board.</p>
                           <p><b>How do you pick the right partners to accelerate digital transformation?</b></p>
                           <p>An African proverb says that if you want to go fast, go alone. If you want to go far, go together. I choose partners based on the journey I wish to undertake and the destination I would like to reach. Any difficult journey is possible if the company is right. Competency, Commitment and Complementarity remain my key partner selection considerations. Competency to execute the vision together is the first obvious consideration. Competency also creates mutual respect and makes the relationship sustainable. Commitment is of paramount importance as it is highly likely that for any difficult transformation, things will go wrong. Unless there is a strong commitment to the cause, the partnership will fizzle out. Finally, any partnership should create synergy and complementarity of skillsets and also maximise value creation for both partners. Most of my partners are the extended arm of my organisation. I am immensely grateful to my partners for all the successful missions.</p>
                           <p><b>What are the top challenges in your business transformation process?</b></p>
                           <p>The biggest challenges are the misalignment of people, process, technology, and the gap that exists between strategy and execution. Most traditional organisations that are in the early days of transformation have siloed IT processes and data, disjoint business processes, high technical and process debts due to the legacy systems. People’s resistance to change comes from the “Not-Invented-Here” syndrome, potential fear of loss of power and many even consider business transformation as a direct threat to their jobs. Many organisations think they are doing transformation by just launching a set of digital initiatives. But solving a wrong problem efficiently with technology is a bigger problem than not solving it at all.</p>
                           <p>In our transformation approach, we apply “Enterprise Architecture” for creating the business transformation blueprint. We analyse an enterprise through the lenses of business processes, technology, data, and applications. We understand the As-Is state of the Enterprise and then define the To-Be state in alignment with the larger corporate strategy prior to building the roadmap to execute the vision. In summary, we use Enterprise Architecture to define the “right things” and then build an execution plan to do things the “right way”.</p>
                           <p><b>What aspect(s) of business should companies prioritise as they digitally transform? Why?</b></p>
                           <p>The top three priorities of digital transformation are business growth, cost reduction through efficiency and productivity enhancement and transformation of the customer experience. A study by MIT shows that for the Digital Champions, which is typically the top 8% of those who undergo a transformation, the impact on EBIT is much larger than growth. This is simply because there is greater organisation control on cost, compared to the growth that is dependent on external factors. However, customer experience improvement through digital transformation gives lots of quick wins and creates new energy for the organisation. My recommended approach is to first identify all the opportunities for transformation and categorise them along the lines of “Stop the bleeding”, “Get Fit” and then “Go for Gold”. Stop the bleeding initiatives are painkillers that solve the immediate and important problems of the organisations. “Get Fit” category solutions create efficiency and productivity and create stability. The “Go for Gold” initiatives are the ones that bring in not 10%, but 10X improvement from the current state. However, cost benefit analysis must be done before embarking on the transformation journey.</p>
                           <p><b>How do you achieve successful change management and shifts in culture, mindset, and behaviours to adopt new digital tools?</b></p>
                           <p>Change management must be done with a human-centric approach. Any new technology introduction is change management. It changes people’s lives and even people’s vocabulary. Digital technologies make data transparent, and at times eliminate middlemen, which can be scary for people that are part of the ecosystem. Corporate transformation is essentially a combination of cultural transformation, business transformation and digital transformation, that essentially defines the “Why”, “What” and “How” of change management – in that order. Technology defines the “How” part, which is the easier bit. The “Why” is defined by the culture and that aspect must be defined by the leaders at the top. The “What” aspect of change defines the business process transformation need. This exercise is led by middle management in a collaborative and inclusive way.</p>
                           <p> User involvement in the early phase of transformation and training are other very crucial aspects of successful change management. However, the most important ingredient of a successful change management program is communication across all levels of the organisation. Many transformation ideas just remain board room conversations and in PowerPoint presentations. But change management happens when such ideas are liberated and discussed in pantries or during lunch.</p>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>


                           </ul>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <PressReleaseLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default PressReleaseArea;