import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import SustainabilityESG02DetailsArea from './SustainabilityESG02DetailsArea/SustainabilityESG02DetailsArea';
import SustainabilityESG02Header from './SustainabilityESG02Header/SustainabilityESG02Header';

const SustainabilityESG02 = () => {
   return (
      <>
         <PageHelmet pageTitle="Making and Achieving Net Zero Pledges" />

         <Header/>
         <SustainabilityESG02Header />
         <SustainabilityESG02DetailsArea />
         <Footer />
      </>
   );
};

export default SustainabilityESG02;