import React from 'react';
import { Link } from 'react-router-dom';

const ServicesISDetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>Services</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><Link to="/enterprise-architecture">Enterprise Architecture</Link></li>
                                    <li><Link to="/information-security">Information Security</Link></li>
                                    <li><Link to="/platform-development">Platform Development</Link></li>
                                    <li><Link to="/internet-of-value">Internet of Value</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="services__text">
                        <p>Businesses lost more than $10 billion to cybercrime in the United States alone in 2023; by the end of 2025, that might rise to $10.5 trillion worldwide!</p>
                        <p>With digital transformation kicked into gear, investment in transformative technologies and processes makes no sense if your business isn’t protected. Inexhaustible data and numerous touchpoints are created during the transformation process. Protecting and managing this information with digital governance and customized solutions is what we do at SOLiD Inspire; we help implement and create strategic and tactical roadmaps that will strengthen your security systems.</p>
                        <p>Our experts put the strategy into action by configuring or implementing the necessary security measures, establishing comprehensive security assessments, mitigating damages from past attacks, etc.</p>
                        <p>Our security solutions include,</p>
                     </div>
                     {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                     <div className="services__list mb-40">
                        <ul>
                           <li>Risk assessment & identification</li>
                           <li>Monitoring & testing</li>
                           <li>Control of infrastructure</li>
                           <li>Command centers (Operations, network & security)</li>
                           <li>Business process health monitoring</li>
                           <li>Application health monitoring</li>
                           <li>Business functions health monitoring, etc.</li>
                        </ul>
                     </div>
                     <div className="services__text">
                        <p>Our expertise lies in bullet-proofing enterprise information for all types and sizes of businesses from different industries (healthcare, life sciences, manufacturing, government, and nonprofit organizations)</p>
                        <p>Our goal is to provide digital transformation with cyber-resilience and security, as well as to develop long-term cyber skills to shield your company's sensitive data from the growing threat of cybercrime.</p>
                        <p>Enhance your enterprise reputation with SOLiD's effective information security practices- get started now!</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesISDetailsArea;