import React from 'react';
import PressReleaseLeftSideBar from '../../PressRelease/PressReleaseArea/PressReleaseLeftSideBar';

// // all category
// const allCategory = ['see all', ...new Set(data.map(category => category.category))]
// // array unique items
// const arr = data;
// const uniqueItem = arr.filter((arr, index, self) =>
//    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PressReleaseArea = () => {
   // active btn
   // const [active, setActive] = useState('see all');
   // const [filterPortfolio, setFilterPortfolio] = useState(uniqueItem);
   // const [values, setValues] = useState(4);

   // // filtering portfolio data
   // const filterCategory = (category) => {
   //    setActive(category)
   //    if (category === 'see all') {
   //       return setFilterPortfolio(uniqueItem)
   //    }
   //    const remainingItems = data.filter((item) => item.category === category);
   //    setFilterPortfolio(remainingItems)
   // }
   // // handleLoadMore
   // const handleLoadMore = () => {
   //    setValues((value) => value + 1)
   // }
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                           <h3>CXBuzz Interview with Mrinmoy Chakraborty, Head of Digital Transformation Business at SOLiD Technologies</h3>
                           <i>By Efrat Vulfsons</i>
                           <p>September 23, 2021</p>
                           <p><b>Hi Mrinmoy, tell us about yourself and your background.</b></p>
                           <p>I grew up in Chandannagar, a beautiful, serene riverside town in West Bengal. As the youngest child, I had a simple, protected childhood, surrounded by my loving elder brother, sister, and other family members. My mother, a school teacher, taught me how education could be my tool to create a better world. My father, a hard-working government official, taught me to be the best version of myself. I have been fortunate to study in some of the finest institutions in India and abroad. I studied Electronics Engineering at Jadavpur University, Kolkata and did my first MBA at National Institute of Industrial Engineering (NITIE), Mumbai. After gaining a few more years of work experience, I went to Stanford Business School to do my second master’s in business as a Sloan Fellow.</p>
                           <p>During my 18 years of professional life, I built and led technology businesses in the US, UK, India, South Korea, and Saudi Arabia. Since 2010, after co-founding my first startup from Stanford, I have been a serial techpreneur. My passion has been to unleash the potential of traditional enterprises leveraging digital technologies. I love to combine business, innovation, and storytelling. As a curious problem solver, I also have 24 international patents.</p>
                           <p>I currently lead Digital Transformation Business at SOLiD, Korea and also serve as the Chief Transformation Officer at Al Dabbagh Group, a conglomerate in Saudi Arabia. I’ve been living in South Korea since 2017, with my wife and two boys; they complete me.</p>
                           <p><b>How did you start working in the customer experience space?</b></p>
                           <p>In 2007, my mentor Ahmad Chatila, a visionary global leader, taught me that any company can excel in three key areas: product innovation, operational excellence, and customer intimacy. I chose customer intimacy as my focus area since it involves people. Innovation has little value unless it touches the lives of others. Customer experience has been a talked about subject in the consumer space for quite long, but neglected in the enterprise (B2B) businesses. I was in semiconductor business at that time and few in my industry talked about customer experience back then. I heard about the “Net Promoter Score” and “Ease of doing business” for the first time in 2009. Since then, I have tried to think and implement exceptional customer experience in enterprise business, the consumer way. Every business I worked on since then had customer intimacy at its core.</p>
                           <p>Many companies think that customer experience is typically the call center experience. But it is essentially a reflection of the culture and competency of every department in the organization. Customer experience is the lifetime relationship experience with the company defined by instant value a product or service creates, its long term value. A great experience is designed with data, tools and emotion.</p>
                           <p>A lot has changed in the CX space in the past 5-7 years. Apple and Amazon deserve a lot of credit for shaping the way the industry thinks today. The beauty of customer experience is that it evolves as people’s behavior and expectations change over time; a great experience is a mix of art, heart and smart. This domain will be relevant as people do business in the planet.</p>
                           <p><b>What do you think the top priority should be for a company that wants to improve its customer experience?</b></p>
                           <p>Genuine empathy for the customer, humility and openness to listen to feedback objectively and ability to use data and digital technologies to act upon the feedback quickly should be the top three priorities for any company that is serious about CX. Exceptional customer experience can only be created when there is a commitment to build a long term relationship with the customer.  It is like building any human relationship. Technology can facilitate the process but the commitment from the top and genuine intent from the employees on the ground are key. I would also go to the extent saying that only companies that are committed to create great employee experience can create great customer experience. If the employees are not happy, it is hard to make the customers happy.</p>
                           <p><b>How can companies better listen and understand their customer base?</b></p>
                           <p>The first step of listening is to clearly understand who the true customer is for an organization and then capturing various “moments of truth” data across the consumer journey. Social media is probably the best listening tool available today. In many ways, Facebook and Twitter are the new digital contact centers. Every customer touchpoint is an opportunity for an organization to collect data and real time feedback. Every interaction with customer may result in 3 scenarios — better experience, bitter experience or same experience. Digital tools should provide such insights after every customer interactions with product, people and the overall company brand. Given the ability to collect vast amount of customer interaction data at relatively low cost, companies can perform more experiments with customers. In a way, CX is a journey of constant relationship experiment.</p>
                           <p><b>Many companies are currently undergoing digital transformation processes. What are your tips on a successful digital transformation?</b></p>
                           <p>Digital transformation is about using digital technologies to transform business capabilities and outcomes. This is a corporate wide change management program that involves people, process, technology and culture. In 2020, over $1.3 trillion dollars were invested in digital transformation, out of which $900B did not produce result (Source: HBR) . Only 8% of organizations, the so-called digital masters, actually succeed in digital transformation. I have learned that any successful transformation must ensure that we solve the right problem. Digitizing a broken process magnifies the in-efficiencies.  Companies should ideally re-imagine business first and then work backwards towards technology. This essentially means transforming business before applying technology. Third, organizations should aspire to disrupt business, but change people behavior slowly, naturally. Finally organizations must remember that business and people are the true heroes in this transformation initiatives, technology is hero’s best friend.</p>
                           <p><b>What are some CX companies/solutions you’re keeping your eyes on right now</b></p>
                           <p>I keep a constant eye on the CX function of every company within my industry ecosystem. My list of favorites keep changing. However Google, Apple and Amazon are the only constants over the past 10 years. Ironically, when it comes to CX, most companies suffer from the so-called “above average syndrome” . According to a Bain study, 8% of the customers think that companies are providing superior service to customers. But 80% of the companies think that they belong to the top 8%. So in reality very few companies understand and practice true CX. The top 5 social listening tools and the behavioral analytics tools have the potential to create similar result if the CX tool users are well trained and implement  the tool right way.  When it comes to any such CX tool, it is not always the best, but the “most appropriate technology” in the context of business that wins. I see now, RPA, AI and Chatbots completely changing the CX space, faster than we can imagine. But the true success of the CX happen when these tools or solutions are seamlessly integrated with the rest of the IT systems and processes in the organization. When the CX systems, legacy ERPs, Omni-channel store fronts , all can exchange data and are able to create a unified and single customer view and experience, magic happens.</p>
                           <p><b>What are some of your tips for people who want to work in the CX sphere?</b></p>
                           <p>I would urge people working in CX space to understand their true power based on the impact they can potentially create in the lives of the customers and on the organization.  CX professionals are truly the brand ambassadors, product evangelists and problem solvers. One of the leading e-commerce companies in India made sure that all the top executives spend some amount of time in contact centers when they join the organization. Next generation CX professionals also need to be learn data savvy. Finally, CX professionals must genuinely care about people. Good old empathy and kindness go a long way.</p>
                           <p><b>So many things changed in 2020. While some things are going to return to “normal,” what are new trends and habits you think will stay with us in the long term?</b></p>
                           <p>The possibility and the current reality of everything doing online has essentially created a “digital twin” of every business. The service expectation from customers on digital channels is ever increasing.  For example, in  e-commerce, same day delivery will be taken for granted in the future. This will not only create pressure on the supply chains and also will create new service delight expectation from the customers. CX professionals will have an exciting challenge to meet up with the expectation. Tools such as Chatbots, AI and RPA will soon become the new normal, not as novelty, but way of doing business. I think “smart digital experience” for the customers both in B2B and B2C world will become the biggest competitive differentiators.  Company need to be ready for the future that is already happening now.</p>
                           <p><b>E-commerce boomed in 2020, and consumers started leaving more product reviews online. How can we make the most out of this momentum?</b></p>
                           <p>Omni channel product reviews, in-apps, and social media can provide enormous amount of valuable data and insights to companies. This data can be used not only for product innovations, feature enhancements and service reimagining but can even trigger creation of new business models.</p>
                           <p><b>What is your favorite CX metric? Why?</b></p>
                           <p>It is Customer Lifetime Value for me. However this score is available only when there is certain digital maturity in the organization. In absence of CLV, I would go with NPS, supplemented by Customer churn rate, and Retention Rate. But the customer lifetime value is the summation of all the metrics in the long run. The success of CX function over time probably can be measured over the improvement of CLV over the years. To me, CLV is as important as the stock price of companies.</p>



                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                           </ul>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <PressReleaseLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default PressReleaseArea;