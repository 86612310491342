import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';

const BlogDetails17 = () => {
   return (
      <>
         <PageHelmet pageTitle="Tech for Good: How Innovation is Tackling Sustainability Challenges" />

         <Header/>
         <CommonPageHeader title="Tech for Good: How Innovation is Tackling Sustainability Challenges" subtitle="Blog Details" />
         <BlogDetailsArea/>
         <Footer/>
      </>
   );
};

export default BlogDetails17;