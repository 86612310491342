import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import CareersArea from './CareersArea/CareersArea';
import JobPost from './JobPost/JobPost';

const Careers = () => {
   return (
      <>
         <PageHelmet pageTitle="Careers" />
         <Header/>
         <CommonPageHeader title="Join the Rocketship" subtitle="Become a part" />
         <CareersArea/>
         <JobPost/>
         <CommonCtaArea talk="Let’s Talk" title="Can’t find the position that's right for you?" text="No worries, reach us out. We may have an opportunity for you.
" linkText="Let’s Talk" />
         <Footer/>
      </>
   );
};

export default Careers;