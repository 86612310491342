import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyLeftSideBar from '../../CaseStudies/CaseStudiesArea/CaseStudyLeftSideBar';

const CaseStudyArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                           <h4>Overview</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <p>
                              <b>A major energy conglomerate in the Kingdom of Saudi Arabia (KSA) sought to modernize its operations across finance, human resources, and enterprise-wide systems to enhance operational efficiency and strategic alignment. The group overcame significant challenges by implementing targeted solutions, driving productivity, and creating measurable business value.</b>
                           </p>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Challenges</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Finance Transformation:</b> Complex financial processes and inconsistent data flow across business units limited financial visibility and impacted decision-making.</li>
                              <li><b>Human Resources Transformation:</b> HR processes were inefficient, resulting in poor employee experience and delayed responses to staffing needs.</li>
                              <li><b>Enterprise Transformation:</b> There is a need for cohesive enterprise management</li>

                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Solutions Provided</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Finance Transformation:</b>A streamlined financial management system was implemented, consolidating financial data and improving the accuracy and speed of reporting. This enabled more agile financial planning and better alignment with corporate goals.</li>
                              <li><b>Human Resources Transformation:</b>An HR digital platform was introduced to automate routine tasks, streamline recruitment, and enhance employee engagement. This provided managers with better tools for workforce planning and improved the employee experience.</li>
                              <li><b>Enterprise Transformation:</b>A centralized enterprise management platform was established to integrate all business units. The platform enabled real-time collaboration, strategic alignment, and facilitated data-driven decision-making at an enterprise level.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Business Value</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>Enhanced financial transparency and faster, more informed decision-making.</li>
                              <li>Increased operational efficiency within HR, leading to improved employee satisfaction and retention.</li>
                              <li>Strengthened organizational alignment with strategic goals, fostering a more unified and agile enterprise capable of responding to market changes.</li>
                              <li>This digital transformation empowered the energy group to position itself as a leading innovator in KSA’s energy sector, enabling it to maintain competitiveness and operational excellence in a rapidly evolving market landscape.</li>
                           </ul>
                        </div>
                        <div>
                           <Link to="/assets/case-studies/solid-inspire-case-study-energy.pdf" target='_blank' className='card download-casestudy stretched-link'>
                              <p className='mb-0'>Download <br /> Energy Industry <br /> Case Stady</p>
                              <img src="/assets/img/icon/download-pdf.png" className='ml-30 p-2 img-fluid' alt="" />
                           </Link>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <CaseStudyLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default CaseStudyArea;