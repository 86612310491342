import React from 'react';
// import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import InterviewsArea from './InterviewsArea/InterviewsArea';

const Interviews = () => {
   return (
      <>
         <PageHelmet pageTitle="Interviews" />
         <Header/>
         <CommonPageHeader title="Interviews" subtitle="Digital" />
         <InterviewsArea/>
         {/* <CommonCtaArea talk="Let’s Talk" title="Can’t find the position that's right for you?" text="No worries, reach us out. We may have an opportunity for you.
" linkText="Let’s Talk" /> */}
         <Footer/>
      </>
   );
};

export default Interviews;