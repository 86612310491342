import React from 'react';
import { Link } from 'react-router-dom';

const ServicesPDDetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
               <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>Services</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><Link to="/enterprise-architecture">Enterprise Architecture</Link></li>                                    
                                    <li><Link to="/information-security">Information Security</Link></li>
                                    <li><Link to="/platform-development">Platform Development</Link></li>
                                    <li><Link to="/internet-of-value">Internet of Value</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="services__text">
                        <p>SOLiD Inspire integrates your core applications, legacy, SaaS, and Customs on-premises and cloud, creating a seamless integration of data and disruptive business value.</p>
                        <p>Our platform “MyPrism” offers seamless integration of various applications to a unified data backbone. The unified data backbone allows the “Business Visibility” platform to provide “Decision as a Service” to key stakeholders.</p>
                        <p>We integrate, automate, and optimize processes helping enterprises capitalize on all the data aggregated from devices everywhere.</p>
                        <p>MyPrism Platform services help in the productive collection and exchange of data from physical objects, “things” embedded with sensors, software, and other tech, and process it for the organization’s innovations, improvements, and all sorts of applications.</p>
                     </div>
                     
                     <div className="services__list mb-40">
                        <ul>
                           <li>The data from devices is flawlessly integrated with applications, cloud services, and systems to build innovative solutions that are a perfect combination of physical and digital assets.</li>
                           <li>Actions, processes, workflows, and other work-related activities are automated through integration and are enabled to perform independently as per predefined business rules.</li>
                           <li>By combining contextual data with real-time data, we obtain comprehensive ideas to make smart decisions.</li>
                        </ul>
                     </div>
                     <div className="services__text">
                     
                     </div>
                     <div className="services__text">
                        <h3>Our Innovation Partner</h3>
                     </div>
                     <div className="services__img mb-45 w-img w-25">
                        <img src="assets/img/services/btf-logo.png" alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesPDDetailsArea;