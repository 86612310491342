import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import CaseStudyArea from './CaseStudyArea/CaseStudyArea';

const CaseStudy03Healthcare = () => {
   return (
      <>
         <PageHelmet pageTitle="Digital Transformation in Biopharmaceuticals | Healthcare Industry" />
         <Header/>
         <CommonPageHeader title="Digital Transformation in Biopharmaceuticals" subtitle="Case Studies" />
         <CaseStudyArea/>
         <Footer/>
      </>
   );
};

export default CaseStudy03Healthcare;