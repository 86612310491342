import React from 'react';
import { Link } from 'react-router-dom';

const SustainabilityESG02DetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>ESG Transformation</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><Link to="/future-proof-your-business">Future-proof your Business</Link></li>                                    
                                    <li><Link to="/making-achieving-net-zero-pledges">Making and Achieving Net Zero Pledges</Link></li>
                                    <li><Link to="/unleashing-the-s-g-factors-in-esg-transformation">Unleashing the S and G factors in ESG transformation</Link></li>
                                    <li><Link to="/the-winning-formula-for-sustainable-business-success">The Winning Formula for Sustainable Business Success</Link></li>
                                    <li><Link to="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024">Sustainability Starts at Home: How to Live a Greener Life in 2024</Link></li>
                                    <li><Link to="/esg-beyond-2024-a-glimpse-into-the-future-of-sustainable-business">ESG Beyond 2024: A Glimpse into the Future of Sustainable Business</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="services__text">
                        <p>Simply put, Net Zero will be achieved when all the emissions that are released by human activities (in this context, business operations) are balanced out by removing carbon from the atmosphere, a process known as carbon removal. Carbon removal can be achieved through natural processes (vegetation, forests, etc) and human interventions like carbon capture & storage. To reach net zero goals, within a specified time, nations, countries, businesses, and even individuals, have issued net zero pledges. </p>
                        <p>As the urgency of addressing climate change has become increasingly recognized, these pledges have attracted a lot of attention. By setting an aspirational target of 1.5 degrees Celsius, the Paris Agreement, a historic international climate agreement enacted in 2015, was instrumental in advancing global efforts to keep global warming far below 2 degrees Celsius.</p>
                        <p>An increasing number of major economies, including China, the USA, India, etc. have expressed pledges to establish net-zero targets. A net-zero aim now covers over 90 nations!
All these announcements seem very encouraging and sound as if we are making rapid progress. But the reality is that net zero pledges are far easier to announce and commit than implement.</p>
                     </div>
                     <div className="services__text  mb-20">
                        <h3>Key Takeaway</h3>
                        <p>Meeting net zero pledges involves consideration of various factors and challenges:</p>
                     </div>
                     <div className="services__list mb-40">
                        <ul>
                           <li>Ambition and credibility</li>
                           <li>Policy and Implementation</li>
                           <li>Carbon offsets</li>
                           <li>Technological challenges</li>
                           <li>International cooperation</li>
                        </ul>
                     </div>
                     
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default SustainabilityESG02DetailsArea;