import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { BiMap } from 'react-icons/bi';
import { FaEnvelope, FaHome } from 'react-icons/fa';

const Sidebar = ({ show, handleClose }) => {

   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  <div className="logo">
                     <a href="/">
                        <img src="assets/img/logo/solid-inspire-logo-white.svg" alt="logo" />
                     </a>
                  </div>
               </Offcanvas.Header>

               <Offcanvas.Body>
                  <section>
                     <div className="p-0">
                        <div className="sidebar__tab">
                           <ul className="nav nav-tabs" id="sidebar-tab" role="tablist">
                              <li className="nav-item">
                                 <a className="nav-link active" id="menu-tab" data-bs-toggle="tab" href="#menu" role="tab" aria-controls="menu" aria-selected="true">menu</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" id="info-tab" data-bs-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="false">info</a>
                              </li>
                           </ul>
                        </div>
                        <div className="sidebar__content">

                           <div className="tab-content" id="sidebar-tab-content">
                              <div className="tab-pane fade show active" id="menu" role="tabpanel" aria-labelledby="menu-tab">

                                 <div className='side_navBar'>
                                    <NavLink to="/" className="text-24px"> {<FaHome />}</NavLink>

                                    <Collapsible trigger={<NavLink to="/about">We </NavLink>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white">
                                          <li><NavLink to="/about">About Us</NavLink></li>
                                          <li><NavLink to="/team">Our Team</NavLink></li>
                                          <li><NavLink to="/careers">Careers</NavLink></li>
                                       </ul>
                                    </Collapsible>

                                    <Collapsible trigger={<NavLink to="/services">Create</NavLink>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white">
                                          <li><NavLink to="/enterprise-architecture">Enterprise Architecture</NavLink></li>                                            
                                          <li><NavLink to="/information-security">Information Security</NavLink></li>
                                          <li><NavLink to="/platform-development">Platform Development</NavLink></li>
                                          <li><NavLink to="/internet-of-value">Internet of Value</NavLink></li>
                                          <li><NavLink to="/esg-transformation">ESG Transformation</NavLink></li>
                                       </ul>
                                    </Collapsible>

                                    <Collapsible trigger={<NavLink to="/esg-transformation">Sustainable</NavLink>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white">                                          
                                          <li><NavLink to="/about-sustainability">About Sustainability</NavLink></li>
                                       </ul>
                                    </Collapsible>

                                    <Collapsible trigger={<NavLink to="/blogs">Digital</NavLink>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white">
                                          <li><NavLink to="/blogs">Blog</NavLink></li>
                                          <li><NavLink to="/press-release">Media</NavLink></li>
                                          <li><NavLink to="/interviews">Interviews</NavLink></li>
                                          
                                       </ul>
                                    </Collapsible>
                                    <NavLink to="/enterprises" >Champions</NavLink>
                                    <div className='about iconAdd border-0'>
                                       <NavLink to="/contact">Interested?</NavLink>
                                    </div>

                                 </div>

                              </div>

                              <div className="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                                 <div className="sidebar__info">
                                    <div className="sidebar__contact mt-30">
                                       <ul>
                                          <li>
                                             <div className="icon">
                                                <i > <BiMap /> </i>
                                             </div>
                                             <div className="text">
                                                <span>2nd Floor, SOLiD Space, 220 Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, 13493, Rep. of Korea</span>
                                             </div>
                                          </li>
                                          <li>
                                             <div className="icon">
                                                <i > <BiMap /> </i>
                                             </div>
                                             <div className="text">
                                                <span>Lattitude: 37.4016° N, <br />
                                                   Longtitude: 127.1084° E<br />
                                                   Attitude: 180 Degrees from the rest of the world</span>
                                             </div>
                                          </li>
                                          <li>
                                             <div className="icon">
                                                <i > <FaEnvelope /> </i>
                                             </div>
                                             <div className="text ">
                                                <span><a href="mailto:Info@solidinspire.com">Info@solidinspire.com</a></span>
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;