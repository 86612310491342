import React from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';

const ServicesOne = () => {
   return (
      <>
         <section className="capabilities__area p-relative black-bg-2 pt-180 pb-155 fix">
            <div className="capabilities__thumb p-absolute" style={{ background:`url(assets/img/capabilities/capabilities-img.jpg)`
               , backgroundPosition: 'center', backgroundSize:'cover'}}></div>
            <div className="capabilities__shape p-absolute wow fadeInLeft" >
               <img src="assets/img/capabilities/capabilities-shape.png" alt="shape"/>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="capabilities__content wow fadeInUp" data-wow-delay=".4s">
                        <div className="section__title section__title-2 mb-60">
                           <h2 className="white-color">Enterprise Architecture as a service</h2>
                        </div>
                        <div className="capabilities__content">
                           <p className='white-color'>EA is the blueprint or navigation map for every successful transformation journey. EA looks at every organization through 4 different lenses – Business processes, Data, Technology, and Applications. This service creates the “As-Is” view of the enterprises, identifies gaps or opportunities for value realization then defines the future state based on future business aspirations.</p>
                        </div>
                        <Link to="/enterprise-architecture" className="z-btn z-btn-border white-color" >Learn more<i > <CgArrowLongRight /> </i></Link> 
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesOne;