import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import PressReleaseArea from './PressReleaseArea/PressReleaseArea';

const PressRelease02 = () => {
   return (
      <>
         <PageHelmet pageTitle="Press Release" />
         <Header/>
         <CommonPageHeader title="Making News" subtitle="Press Release" />
         <PressReleaseArea/>
         <Footer/>
      </>
   );
};

export default PressRelease02;