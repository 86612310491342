import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import SustainabilityESG05DetailsArea from './SustainabilityESG05DetailsArea/SustainabilityESG05DetailsArea';
import SustainabilityESG05Header from './SustainabilityESG05Header/SustainabilityESG05Header';

const SustainabilityESG05 = () => {
   return (
      <>
         <PageHelmet pageTitle="Sustainability Starts at Home: How to Live a Greener Life in 2024" />

         <Header/>
         <SustainabilityESG05Header />
         <SustainabilityESG05DetailsArea />
         <Footer />
      </>
   );
};

export default SustainabilityESG05;