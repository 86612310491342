import React from 'react';
import { Link } from 'react-router-dom';

const ServicesEADetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>Services</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><Link to="/enterprise-architecture">Enterprise Architecture</Link></li>
                                    <li><Link to="/information-security">Information Security</Link></li>
                                    <li><Link to="/platform-development">Platform Development</Link></li>
                                    <li><Link to="/internet-of-value">Internet of Value</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="services__text">
                        <p>Enterprise Architecture is about viewing the enterprise as an ecosystem in which business functions operate, creating the conceptual blueprint or navigation map of every business and defining its structure and operations.</p>
                        <p>At SOLiD Inspire, our “Enterprise architecture model” comprises all five architectural components essential for a business to level up.</p>
                     </div>
                     <div className="services__list mb-40">
                        <ul>
                           <li>Organizational Architecture</li>
                           <li>Business Architecture</li>
                           <li>Information Architecture</li>
                           <li>Application Architecture</li>
                           <li>Technological Architecture</li>
                        </ul>
                     </div>
                     <div className="services__text">
                        <p>Our primary goal in Enterprise Architecture as a service is to offer ideal roadmaps for every enterprise’s transformation and redesign.
                           With systemized analysis, planning, designing, and execution, our EA solutions will aid organizations in attaining their current and future objectives.
                           We conduct a thorough analysis of your current systems and processes, identifying opportunities for optimization and innovation.</p>

                        <p>From designing robust IT frameworks to aligning technology with your business objectives, we're committed to delivering results that drive growth and efficiency. Elevate your business to new heights – partner with us today!
                           SOLiD Inspire, helps enterprises undergo effective digital transformation by bridging the gap between existing processes and technology.
                           Throughout the process, our expert team oversees, improves, and upgrades the enterprise’s services, software, and hardware as one.
                           Altogether, we lay a clear and robust architectural foundation for a scalable digital future for every enterprise.</p>
                        <p>From Blueprint to Reality: our modern enterprise architecture services help create a roadmap for your long-term perspective of your business operations and a scalable digital future.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesEADetailsArea;