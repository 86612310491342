import React from 'react';
import HomeMyPrismSingleFeature from '../../../components/HomeMyPrismSingleFeature/HomeMyPrismSingleFeature';

const HomeMyPrism = () => {
   return (
      <>
         <section className="features__area pt-115 pb-120 home-WhoAreWe">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="features__content-left text-center">
                        <div className="section__title section__title-h2 mb-25">
                           <div className="myprism-logo">
                              
                                 <img src="assets/img/logo/logo-title-center.svg" alt="MyPrism logo"/>

                           </div>
                           {/* <span>MyPrism</span> */}
                           <h2>End-to-end strategy to execution partner for your enterprise</h2>
                        </div>
                        <p>MyPrism bridges strategy and operations by providing real-time visibility of key performance indicators (KPIs), processes, and information flow</p>
                        {/* <Link to="/services" className="z-btn">What we do<i><CgArrowLongRight/></i></Link> */}
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <div className="features__content-right">
                        <div className="row">
                           <div className="col myprism wisdom">
                              <HomeMyPrismSingleFeature image="1" title="Wisdom" />
                           </div>
                           <div className="col myprism action">
                              <HomeMyPrismSingleFeature image="2" title="Action" />
                           </div>
                           <div className="col myprism elevate">
                              <HomeMyPrismSingleFeature image="3" title="Elevate" />
                           </div>
                           <div className="col myprism navigate">
                              <HomeMyPrismSingleFeature image="4" title="Navigate" />
                           </div>
                           <div className="col myprism journey">
                              <HomeMyPrismSingleFeature image="5" title="Journey" />
                           </div>
                           <div className="col myprism eureka">
                              <HomeMyPrismSingleFeature image="6" title="Eureka" />
                           </div>
                           <div className="col myprism planet">
                              <HomeMyPrismSingleFeature image="7" title="Planet" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeMyPrism;