import React, { useState } from "react";
import CommonCtaArea from "../../components/CommonCtaArea/CommonCtaArea";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import Header from "../../components/shared/Header";
import SustainabilityESGLead from "./SustainabilityESGLead/SustainabilityESGLead";
import VideoPopup from "../../components/VideoPopup/VideoPopup";

const SustainabilityESG = () => {
  // const { setIsVideoOpen } = useGlobalContext();
  const [activeVideoId] = useState();

  console.log("url test===>", activeVideoId);

  return (
    <>
      <PageHelmet pageTitle="Sustainability transformation impacts every aspect of a business- here's what we think" />
      <Header />
      <CommonPageHeader
        title="Demystifying ESG Transformation"
        subtitle="Our Services"
      />
      <SustainabilityESGLead />
      {/* <SustainabilityESGOne
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("Rxe0u9QXpFI")}
      />
      <SustainabilityESGTwo
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("kn9Kyi58C60")}
      />
      <SustainabilityESGThree
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("7lzR1LC8a10")}
      />
      <SustainabilityESGFour
        open={setIsVideoOpen}
        hanleUrl={() => setActiveVideoId("D1hYYcCvueA")}
      /> */}
      <CommonCtaArea title="Sustainability Transformation" linkText="Start Now" />
      <VideoPopup videoId={activeVideoId} />
      <Footer />
    </>
  );
};

export default SustainabilityESG;
