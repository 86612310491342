import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import ServicesEADetailsArea from './ServicesEADetailsArea/ServicesEADetailsArea';

const ServicesEA = () => {
   return (
      <>
         <PageHelmet pageTitle="Enterprise Architecture Consulting" />

         <Header/>
         <CommonPageHeader
            title="Enterprise Architecture Consulting"
            subtitle="Our Services"
            />
         <ServicesEADetailsArea />
         <Footer />
      </>
   );
};

export default ServicesEA;