import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import PortfolioArea from './PortfolioArea/PortfolioArea';

const Portfolio = () => {
   return (
      <>
         <PageHelmet pageTitle="SOLiD Inspire has revolutionized 9+ industries, powering growth, profitability, and valuation through Transformation and Innovation" />
         <Header/>
         <CommonPageHeader title="Industries" subtitle="Industries" />
         <PortfolioArea/>
         <Footer/>
      </>
   );
};

export default Portfolio;