import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import About from "./pages/About/About";
import Team from "./pages/Team/Team";
import Services from "./pages/Services/Services";
import ServicesEA from "./pages/ServicesEA/ServicesEA";
import ServicesIoT from "./pages/ServicesIoT/ServicesIoT";
import ServicesIS from "./pages/ServicesIS/ServicesIS";
import ServicesPD from "./pages/ServicesPD/ServicesPD";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Portfolio from "./pages/Portfolio/Portfolio";
import CaseStudies from "./pages/CaseStudies/CaseStudies";
import PressRelease from "./pages/PressRelease/PressRelease";
import Careers from "./pages/Careers/Careers";
import Sustainable from "./pages/Sustainable/Sustainable";
import SustainabilityESG from "./pages/SustainabilityESG/SustainabilityESG";
import SustainabilityESG01 from "./pages/SustainabilityESG01/SustainabilityESG01";
import SustainabilityESG02 from "./pages/SustainabilityESG02/SustainabilityESG02";
import SustainabilityESG03 from "./pages/SustainabilityESG03/SustainabilityESG03";
import SustainabilityESG04 from "./pages/SustainabilityESG04/SustainabilityESG04";
import SustainabilityESG05 from "./pages/SustainabilityESG05/SustainabilityESG05";
import SustainabilityESG06 from "./pages/SustainabilityESG06/SustainabilityESG06";
import SustainabilityAbout from "./pages/SustainabilityAbout/SustainabilityAbout";
import Contact from "./pages/Contact/Contact";
// import BlogDetails01 from "./pages/BlogDetails01/BlogDetails01";
// import BlogDetails02 from "./pages/BlogDetails02/BlogDetails02";
// import BlogDetails03 from "./pages/BlogDetails03/BlogDetails03";
import BlogDetails04 from "./pages/BlogDetails04/BlogDetails04";
import BlogDetails05 from "./pages/BlogDetails05/BlogDetails05"
// import BlogDetails06 from "./pages/BlogDetails06/BlogDetails06";
import PressRelease01 from "./pages/PressRelease01/PressRelease01";
import PressRelease02 from "./pages/PressRelease02/PressRelease02";
import PressRelease03 from "./pages/PressRelease03/PressRelease03";
import PressRelease04 from "./pages/PressRelease04/PressRelease04";
import Interviews from "./pages/Interviews/Interviews";
import BlogDetails07 from "./pages/BlogDetails07/BlogDetails07";
import BlogDetails08 from "./pages/BlogDetails08/BlogDetails08";
import BlogDetails09 from "./pages/BlogDetails09/BlogDetails09";
import BlogDetails10 from "./pages/BlogDetails10/BlogDetails10";
import BlogDetails11 from "./pages/BlogDetails11/BlogDetails11";
import BlogDetails12 from "./pages/BlogDetails12/BlogDetails12";
import BlogDetails13 from "./pages/BlogDetails13/BlogDetails13";
import BlogDetails14 from "./pages/BlogDetails14/BlogDetails14";
import BlogDetails15 from "./pages/BlogDetails15/BlogDetails15";
import BlogDetails16 from "./pages/BlogDetails16/BlogDetails16";
import BlogDetails17 from "./pages/BlogDetails17/BlogDetails17";
import CaseStudy01Banking from "./pages/CaseStudy01Banking/CaseStudy01Banking";
import CaseStudy02Retail from "./pages/CaseStudy02Retail/CaseStudy02Retail";
import CaseStudy03Healthcare from "./pages/CaseStudy03Healthcare/CaseStudy03Healthcare";
import CaseStudy04Energy from "./pages/CaseStudy04Energy/CaseStudy04Energy";
import CaseStudy05Airline from "./pages/CaseStudy05Airline/CaseStudy05Airline";
import CaseStudy06Automotive from "./pages/CaseStudy06Automotive/CaseStudy06Automotive";
import CaseStudy07Construction from "./pages/CaseStudy07Construction/CaseStudy07Construction";



function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<HomeTwo/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/enterprise-architecture" element={<ServicesEA/>} />
            <Route path="/internet-of-value" element={<ServicesIoT/>} />
            <Route path="/information-security" element={<ServicesIS/>} />
            <Route path="/platform-development" element={<ServicesPD/>} />
            <Route path="/blogs" element={<Blogs/>} />
             <Route path="/21-things-i-learned-in-2021" element={<BlogDetails/>} /> 
            {/* <Route path="/generosity-is-also-letting-others-give" element={<BlogDetails01/>} />
            <Route path="/life-after-corona" element={<BlogDetails02/>} />
            <Route path="/leading-with-heart-the-inexperienced-candidate" element={<BlogDetails03/>} />*/}
            <Route path="/be-more-do-more" element={<BlogDetails04/>} /> 
            <Route path="/my-ten-cents-on-leadership" element={<BlogDetails05/>} />
            {/* <Route path="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024" element={<BlogDetails06/>} />  */}
            <Route path="/enhancing-business-resilience" element={<BlogDetails07/>} />
            <Route path="/myprism-action-from-chaotic-mind-to-mastermind" element={<BlogDetails08/>} />
            <Route path="/myprism-symphony-orchestrating-success-in-a-complex-world-a-project-managers-journey" element={<BlogDetails09/>} />
            <Route path="/a-pms-adventures-with-journey-module-of-myprism" element={<BlogDetails10/>} />
            <Route path="/embrace-sustainability-a-practical-guide-to-eco-friendly-living" element={<BlogDetails11/>} />
            <Route path="/sustainable-supply-chains-building-resilience-and-transparency-in-the-business-world" element={<BlogDetails12/>} />
            <Route path="/combating-climate-change-sustainable-businesses-can-be-heroes-too" element={<BlogDetails13/>} />
            <Route path="/why-should-your-business-care-about-esg-transformation" element={<BlogDetails14/>} />
            <Route path="/unveiling-sustainable-investment-opportunities-why-msci-matters-in-a-greener-future" element={<BlogDetails15/>} />
            <Route path="/from-awareness-to-action-practical-steps-for-sustainable-transformation" element={<BlogDetails16/>} />
            <Route path="/tech-for-good-how-innovation-is-tackling-sustainability-challenges" element={<BlogDetails17/>} />
            <Route path="/enterprises" element={<Portfolio/>} />
            <Route path="/banking" element={<CaseStudy01Banking/>} />
            <Route path="/retail" element={<CaseStudy02Retail/>} />
            <Route path="/healthcare" element={<CaseStudy03Healthcare/>} />
            <Route path="/energy" element={<CaseStudy04Energy/>} />
            <Route path="/airline" element={<CaseStudy05Airline/>} />
            <Route path="/automotive" element={<CaseStudy06Automotive/>} />
            <Route path="/construction" element={<CaseStudy07Construction />} />
            <Route path="/case-studies" element={<CaseStudies/>} />
            <Route path="/press-release" element={<PressRelease/>} />
            <Route path="/we-will-never-goback-to-the-old-normal-transform-your-business-now-or-you-might-vanish" element={<PressRelease01/>} />
            <Route path="/cxbuzz-interview-with-mrinmoy-chakraborty-head-of-digital-transformation-business-at-solid-technologies" element={<PressRelease02/>} />
            <Route path="/cxo-deep-dive-mrinmoy-chakraborty" element={<PressRelease03/>} /> 
            <Route path="/solids-journey-in-driving-digital-transformation" element={<PressRelease04/>} /> 
            <Route path="/careers" element={<Careers/>} />
            <Route path="/sustainable" element={<Sustainable/>} />
            <Route path="/esg-transformation" element={<SustainabilityESG/>} />
            <Route path="/future-proof-your-business" element={<SustainabilityESG01/>} />
            <Route path="/making-achieving-net-zero-pledges" element={<SustainabilityESG02/>} />
            <Route path="/unleashing-the-s-g-factors-in-esg-transformation" element={<SustainabilityESG03/>} />
            <Route path="/the-winning-formula-for-sustainable-business-success" element={<SustainabilityESG04/>} />
            <Route path="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024" element={<SustainabilityESG05/>} />
            <Route path="/esg-beyond-2024-a-glimpse-into-the-future-of-sustainable-business" element={<SustainabilityESG06/>} />
            <Route path="/about-sustainability" element={<SustainabilityAbout/>} />
            <Route path="/interviews" element={<Interviews/>} />
            <Route path="/contact" element={<Contact/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
