import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import TeamOne from './TeamOne/TeamOne';
import TeamTwo from './TeamTwo/TeamTwo';
import TeamThree from './TeamThree/TeamThree';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import TeamFour from './TeamFour/TeamFour';

const Team = () => {
   return (
      <>
         <PageHelmet pageTitle="Our Team: Joon CHUNG, Ph.D. - Founder, Mrinmoy Chakraborty - CEO, Bhamidipati Satya - CTO, Lee, Jung Wook - Head of Operations" />

         <Header/>
         <CommonPageHeader title="Meet the Leaders" subtitle="Team" />
         <TeamOne/>
         <TeamTwo/>
         <TeamThree/>
         <TeamFour/> 
         <CommonCtaArea talk="Let’s Talk" title="We deliver Digital Transformation as a Service" linkText="Chat with a Digitalization Expert" />
         <Footer/>
      </>
   );
};

export default Team;