import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyLeftSideBar from '../../CaseStudies/CaseStudiesArea/CaseStudyLeftSideBar';

const CaseStudyArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">

                        <div className="blog__text mb-45">
                           <h4>Challenges</h4>
                        </div>
                        <div className="blog__text mb-45">
                           <p>An automotive group with 135 subsidiaries struggled with high costs, redundant tools, siloed information, and unmonetized ideas that limited business value.</p>>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Solutions</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>A 7-year innovation strategy with a clear vision, mission, and business plan.</li>
                              <li>A unified operating model across subsidiaries, streamlining processes and aligning goals.</li>
                              <li>Key digital tools for <b>Chassis Tracking, Trailer Tracking,</b> and <b>Ground Asset Management</b> to enhance visibility and efficiency.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Business Value</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>Saving over $5 million by consolidating tools and eliminating redundancies.</li>
                              <li>Increasing efficiency, enabling faster decision-making, and optimizing resource allocation.</li>
                              <li>Establishing a structured innovation pipeline that drives monetization and elevates organizational valuation.</li>
                              <li>Creating a scalable model for continuous improvement, enhancing the group's competitive edge.</li>
                           </ul>
                        </div>
                        
                        <div>
                           <Link to="/assets/case-studies/solid-inspire-case-study-automotive.pdf" target='_blank' className='card download-casestudy stretched-link'>
                              <p className='mb-0'>Download <br /> Automotive Industy<br /> Case Stady</p>
                              <img src="/assets/img/icon/download-pdf.png" className='ml-30 p-2 img-fluid' alt="" />
                           </Link>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <CaseStudyLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default CaseStudyArea;