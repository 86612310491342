const TeamThree = () => {
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/team/details/bhamidipati-satya.jpg" alt="" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h3>Bhamidipati Satya </h3>
                           <span>CTO, SOLiD Inspire</span>
                        </div>
                        <p>Satya is a Digitalization Leader delivering value through Business and Digital Transformation. </p>
                        <p>He has 20+ years of track record in building and launching global technology platforms and solutions for customers worldwide.</p>
                        <p>Satya’s expertise ranges in Technology Leadership, Digital Consulting, including Business, Applications, Data, Technology, Infrastructure architectures, etc. </p>
                        <p>He has architected and launched platforms for Industry 4.0, smart cities, smart buildings, smart energy & utilities, smart surveillance & video analytics, smart logistics, etc. </p>
                        <p>Satya has won innovation awards along with funding for building platforms for smart buildings and digitalization.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamThree;