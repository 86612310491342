import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import SustainabilityESG06DetailsArea from './SustainabilityESG06DetailsArea/SustainabilityESG06DetailsArea';
import SustainabilityESG06Header from './SustainabilityESG06Header/SustainabilityESG06Header';

const SustainabilityESG06 = () => {
   return (
      <>
         <PageHelmet pageTitle="Sustainability Starts at Home: How to Live a Greener Life in 2024" />

         <Header/>
         <SustainabilityESG06Header />
         <SustainabilityESG06DetailsArea />
         <Footer />
      </>
   );
};

export default SustainabilityESG06;