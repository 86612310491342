import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import CaseStudiesArea from './CaseStudiesArea/CaseStudiesArea';

const CaseStudies = () => {
   return (
      <>
         <PageHelmet pageTitle="Case Studies" />
         <Header/>
         <CommonPageHeader title="Problems we solved" subtitle="Case Studies" />
         <CaseStudiesArea/>
         <Footer/>
      </>
   );
};

export default CaseStudies;