import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Header from '../../components/shared/Header';
import ServicesISDetailsArea from './ServicesISDetailsArea/ServicesISDetailsArea';

const ServicesIS = () => {
   return (
      <>
         <PageHelmet pageTitle="Information Security" />

         <Header/>
         <CommonPageHeader
            title="Information Security"
            subtitle="Our Services"
            />
         <ServicesISDetailsArea />
         <Footer />
      </>
   );
};

export default ServicesIS;