import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyLeftSideBar from '../../CaseStudies/CaseStudiesArea/CaseStudyLeftSideBar';

const CaseStudyArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                           <h4>Challenges</h4>
                        </div>
                        <div className="blog__text mb-45">
                           <p>Efficiently tracking and managing logistics assets like trailers, chassis, and ground equipment is critical but challenging. Limited visibility can lead to misallocation, underutilization, and higher operational costs.</p>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Solutions</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <p>A smart asset management solution was deployed, featuring:</p>
                           <ul>
                              <li><b>Chassis and Trailer Tracking</b>for precise, real-time monitoring</li>
                              <li><b>Ground Asset Management </b>to keep critical equipment optimized</li>
                              <li><b>Zone Level Tracking </b>to manage asset distribution across expansive areas</li>
                              
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Business Value</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Enhanced Asset Utilization:</b>Real-time tracking optimized asset usage, reducing idle time and improving productivity.</li>
                              <li><b>Operational Efficiency: </b>Streamlined operations with faster response times and better asset coordination.</li>
                              <li><b>Cost Reduction: </b>Efficient asset allocation reduces costs associated with lost, idle, or underutilized assets.</li>
                              <li><b>Data-Driven Decision-Making: </b>Access to comprehensive asset data empowered logistics teams to make informed, proactive decisions, boosting overall performance and agility.</li>
                              
                           </ul>
                        </div>
                        
                        <div>                           
                           <Link to="/assets/case-studies/solid-inspire-case-study-airline.pdf" target='_blank' className='card download-casestudy stretched-link'>
                              <p className='mb-0'>Download <br /> Airline Industy<br /> Case Stady</p>
                              <img src="/assets/img/icon/download-pdf.png" className='ml-30 p-2 img-fluid' alt="" />                           
                           </Link>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <CaseStudyLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default CaseStudyArea;