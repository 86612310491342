import React from 'react';
import BlogLeftSideBar from '../../Blogs/BlogsArea/BlogLeftSideBar';

const BlogDetailsArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <p className='lead'>Hey there, fellow project managers! Sarah here, ready to spill the tea on my latest weapon in the battle against project chaos: the <b>Journey Module.</b></p>
                        <p>You know the drill: overflowing inboxes, missed deadlines, and that sinking feeling when you realize you forgot to assign a task (oops!). But fear not, my friends, for I've discovered a digital knight in shining armor.</p>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>Collaboration? Conquering It!</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <p>Remember those awkward team meetings where everyone pretends to know what's going on? Not anymore! The Journey Module fosters collaboration like never before. Team members can access the project plan, track their progress, and leave comments in real time. It's like having a virtual whiteboard where everyone can contribute and stay on the same page.</p>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>Deadlines? Dethroned!</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <p>Deadlines used to be my arch nemesis. But the Journey Module is like a personal cheerleader, holding me accountable and keeping me on track. It sends automated reminders, highlights overdue tasks, and even allows me to set dependencies between tasks, ensuring a smooth workflow. Now, deadlines don't send shivers down my spine – they're conquerable beasts!</p>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>A Bird's Eye View of Success</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <p>The Journey Module isn't just about managing tasks, it's about seeing the bigger picture. We can generate reports that showcase project progress, identify potential bottlenecks, and adjust before they derail everything. It's like having a bird's-eye view of my project, allowing me to make informed decisions and keep us all on the path to success.</p>
                        </div>
                     </div>
                     <div className="row  mb-20">
                        <div className="col-xl-12 col-lg-12">
                           <h4>The Verdict? A Project Manager's Dream Come True</h4>
                        </div>
                     </div>
                     <div className="row  mb-45">
                        <div className="col-xl-12 col-lg-12">
                           <p>Look, we’ve been in the trenches of project management for years. And the Journey Module of MyPrism is a game-changer. It's streamlined my workflow, boosted collaboration, and given me the peace of mind that comes with knowing everything is under control. So, if you're tired of project chaos and ready to unleash your inner project management hero, I highly recommend checking out the Journey Module of MyPrism. Trust me, it'll be the best decision you make all week (or maybe even all year!).</p>
                        </div>
                     </div>
                     
                  </div>

                  <BlogLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;