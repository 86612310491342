import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';

const BlogDetails16 = () => {
   return (
      <>
         <PageHelmet pageTitle="From Awareness to Action: Practical Steps for Sustainable Transformation" />

         <Header/>
         <CommonPageHeader title="From Awareness to Action: Practical Steps for Sustainable Transformation" subtitle="Blog Details" />
         <BlogDetailsArea/>
         <Footer/>
      </>
   );
};

export default BlogDetails16;