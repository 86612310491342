import React from 'react';
import { Link } from 'react-router-dom';

const VideoSingleItem = ({linkBlogDetails,image,user_image,name,title,video_icon, description}) => {
   return (
      <>
         <div className="blog__item-2 mb-50 fix" >
            <div className={`blog__thumb-2 w-img fix ${video_icon && 'p-relative'}`}>
               <Link to={`${linkBlogDetails}`}>
                  {/* <img src={`assets/img/blog/blog-${image}.jpg`} alt="" /> */}
                  <img src={`assets/img/sustainable/Video-${image}.jpg`} alt="" />
               </Link>
               {video_icon && video_icon}
            </div>
            {/* <div className="blog__content-2">
               <h3>
                  <Link to={`${linkBlogDetails}`}>{title}</Link>
               </h3>
            </div> */}
         </div>
      </>
   );
};

export default VideoSingleItem;