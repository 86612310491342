import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';

const BlogDetails15 = () => {
   return (
      <>
         <PageHelmet pageTitle="Unveiling Sustainable Investment Opportunities: Why MSCI Matters in a Greener Future" />

         <Header/>
         <CommonPageHeader title="Unveiling Sustainable Investment Opportunities: Why MSCI Matters in a Greener Future" subtitle="Blog Details" />
         <BlogDetailsArea/>
         <Footer/>
      </>
   );
};

export default BlogDetails15;