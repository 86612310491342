import React from 'react';
import PressReleaseLeftSideBar from '../../PressRelease/PressReleaseArea/PressReleaseLeftSideBar';

// // all category
// const allCategory = ['see all', ...new Set(data.map(category => category.category))]
// // array unique items
// const arr = data;
// const uniqueItem = arr.filter((arr, index, self) =>
//    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PressReleaseArea = () => {
   // // active btn
   // const [active, setActive] = useState('see all');
   // const [filterPortfolio, setFilterPortfolio] = useState(uniqueItem);
   // const [values, setValues] = useState(4);

   // // filtering portfolio data
   // const filterCategory = (category) => {
   //    setActive(category)
   //    if (category === 'see all') {
   //       return setFilterPortfolio(uniqueItem)
   //    }
   //    const remainingItems = data.filter((item) => item.category === category);
   //    setFilterPortfolio(remainingItems)
   // }
   // // handleLoadMore
   // const handleLoadMore = () => {
   //    setValues((value) => value + 1)
   // }
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                           <h3>'We Will Never Go Back to the Old Normal: Transform Your Business Now Or You Might Vanish'</h3>
                           <p><i>By David Sehyeon Baek</i></p>
                           <p>Surviving the coming economic tsunami</p>
                           <p>Not only the media and social networking sites but also universities, expert groups, and consulting companies are warning that an economic tsunami is approaching. I see countless messages warning me to prepare for this and get ready for even greater economic hardship than during the Great Depression of 1929. Such predictions may not be exaggerated. Economic disruptions caused by the COVID-19 pandemic are witnessed almost everywhere here and now. According to Moody's, China's economic growth rate will drop to 4.0%, and the G20 global economic growth rate is likely to drop to 2.1%. If that is so, we need to act quickly.</p>
                           <p>Recent discussions on how digital and business transformations should take place in the new normal era caused by COVID-19 and how to survive in the aftermath are multiplying. However, digital and business transformations can be poisons, not drugs, if not undertaken appropriately. Decisions on transformation should never be based on the political judgment of those who want to show off their achievements in an organization.</p>
                           <p>Mrinmoy Chakraborty, vice president of digital business at the Internet of Moving Things (IoMT) in Korea's SOLiD, a global telecommunications equipment company, is the company’s first foreign executive. He is already making significant contributions to productivity through digital business transformation services and consulting in the industry. During a one-on-one interview, he shared his insights on surviving the coming economic tsunami.</p>
                           <p><b>Question: Most companies are worried about the effects of COVID-19, and everyone is worried about the economy. We need to be alert and act quickly. Can you tell us how you see this situation?</b></p>
                           <p>Mrinmoy: Of course, this COVID-19 pandemic is painful and can’t be ignored. True entrepreneurs should see this crisis as an opportunity to create solutions. They believe that the more difficult the problem is, the better the solutions they can come up with, and that we should be able to propose and act on creative solutions to overcome this situation. Just suffering and feeling sad and depressed won't help businesses or the economy. I think it's important to put aside fear for the moment, accept reality, and try to overcome the situation. I hope people can find positive alternatives quickly.</p>
                           <p><b>Q: What do you recommend startups, SMEs, large companies, and entrepreneurs to do first in the current situation?</b></p>
                           <p>Mrinmoy: I want to suggest three steps. First, “stop the bleeding.” You have to have some long-term plans, but it is necessary to act quickly in the short run. That is, quickly identify your cash flows and deal with them. For example, figure out how to pay employees’ salaries, how to pay and deal with customers, and how to complete products already in production or under development. The most important thing is managing cash.</p>
                           <p>Take India, for example. As of 2019, the investment in startups in India was USD6.6 billion. Since the COVID-19 pandemic, that investment has now decreased to USD2 billion. Difficulties are expected because the amount of investment in startups or SMEs has been greatly reduced. In the end, managing cash is the most important. So, the first thing entrepreneurs think about as an easy option is firing employees. More than 50% of companies are already laying off workers. There are stories of embarrassing and insensitive notifications of layoffs via Twitter. Managers really need to think about whether this is right. Instead of firing, companies need to keep as many employees as possible, even if salaries must be cut. That way, you can keep your business up and running while making things more bearable for your employees.</p>
                           <p>During the 2008 global financial crisis, companies that tried to retain their employees somehow did well in the long run. Honeywell of the USA is an example. Honeywell managed to avoid dismissals as much as possible during the recession of 2009, partly through unpaid leave. In other words, dismissing employees blindly can reduce costs in the short term, but it is not good for the company in the long term. Employee morale also falls. It would make sense to fire people if we believed that the current economic difficulties would continue indefinitely. But all difficulties have an end, and once the current crisis is over, we will enter the recovery phase.</p>
                           <p>Excellent employees who have been hurt by firings will not likely return to help companies recover. Remember to take into account various aspects of the current crisis and believe that the recovery period will come. In this way, Honeywell managed to keep a good reputation among both its employees and its customers.</p>
                           <p><b>Q: That is good advice, but not easy to follow. Can you give some specifics?</b></p>
                           <p>Mrinmoy: One possibility is to mobilize everything. We need to devise a way to survive while continuing to communicate with customers, talk honestly to investors and employees, minimize shocks, and avoid dismissals. So we should think about “minimizing spending and maximizing investment.” While minimizing spending, rates of return on investments should be ensured. Recent college graduates may be happy to get work experience through internships instead of making as much money as possible immediately. Hiring those graduates is an “investment” for a company, not an expense. This shouldn’t be overlooked. Stopping product development in the middle of the process shouldn’t be seen as minimizing costs but as stopping investment, which is not good for the company, customers, investors, or employees.</p>
                           <p><b>Q: Is there a second step?</b></p>
                           <p>Mrinmoy: The second step is “get fit.” This means honoring commitments by trying every way possible to keep your promises to customers. If you can't keep all your promises even after trying everything, be honest with them and ask for their understanding. For example, if a product is being developed but cannot be 100% completed, offer the minimum viable product to honor your commitment and explain the difficulties faced. It’s also necessary to inform business partners who will be worrying about the situation. Never despair and never give up. There is always a way to think around a problem. By keeping promises to customers, we can secure long-term growth.</p>
                           <p><b>Q: What is the third step you recommend?</b></p>
                           <p>Mrinmoy: The third step is “go for the goal.” This is a measure for the future. According to Microsoft CEO Satya Nadella, the explosive digital transformation occurring over the past two months equals the results of a two-year transformation effort. Companies that were wondering if they would try digital transformation before the COVID-19 pandemic were suddenly forced to introduce it in a hurry.</p>
                           <p>An example is a startup in China. It provides solutionsby visualizing rail-, aviation-, and ship-based supply chains. Aviation and shipping suffered a severe blow as the COVID-19 crisis deepened, with transport falling to almost 0%, but rail transport grew explosively. Supply chain losses through aviation and ships were offset by the growth in rail shipments. This shows that there is always a way to succeed if you observe the situation without giving up, no matter how difficult the business climate may be.</p>
                           <p>It must be pointed out that successful attempts to overcome difficulties contribute to the overall economy. Although the restaurant and catering industry suffered enormously from COVID-10 containment measures, many business owners saw that it was time to transform through delivery services. The real estate industry is struggling because it can't meet buyers and sellers in person, but some are successfully transforming by putting property listings and all transactions online. Every business will have to find its own form of transformation to overcome today’s difficulties. Once again, we must prepare thoroughly for dealing with the current and future crises, while not assuming that this pandemic will last forever.</p>
                           <p><b>Q: Some people argue that after this pandemic the “new normal” of social distancing and lockdowns will disappear. Do you think that we will return to our “old normal”?</b></p>
                           <p>Mrinmoy: I think that’s just the personal opinion of some people. Let's look at the data. According to an announcement by India's largest IT service company with approximately 200,000 employees, 50% will continue to work from home even after the pandemic ends. Analysis of data showed that as the COVID-19 situation progressed, productivity increased significantly due to telecommuting. Fixed costs also fell as office spaces were reduced. Google and Facebook announced that they would extend their telecommuting work arrangements until the end of the year.</p>
                           <p>Of course, it will be difficult for everybody to return to work if COVID-19 is not contained and vaccines are not developed. It doesn’t seem likely that our lives will simply go back to normal after the pandemic. People joke about the changes BC (before coronavirus) and AD (after the disease). But I learned how to get more and better results with fewer resources during the COVID-19 crisis, so it won’t be easy to abandon more productive methods and go back to the old normal.</p>
                           <p><b>Q: Many companies are uncomfortable about working from home arrangements because direct monitoring of employees is not possible. Do you think that new types of software are needed for this?</b></p>
                           <p>Mrinmoy: That's not a problem related to digital transformation, the new normal, or the productivity of telecommuting. I think it's simply a matter of trust. Leaders should know that the cost of distrust is also high. Developing new software for monitoring employees is the wrong problem-solving approach. If you don't believe in and trust your employees, why did you hire them? Once you employ people, it’s a mark of good leadership to believe in and encourage them. Leadership means building trust and working collaboratively to succeed.</p>
                           <p>How can people become more productive? First, if a company believes in its people and feels that they can work more productively and effectively, they probably will. The COVID-19 situation teaches us to trust each other, cooperate, and be more efficient. If this trust-based system is working, I don't think everything will return to the old normal. Some may go back to the past, but I strongly believe that methods that are clearly effective during this current situation will settle into our lives as the new normal.</p>
                           <p><b>Q: Some organizations regard digital conversion as a necessary process. However, a lot of people think that simply moving an offline process online is not a true digital transformation. How would you describe a complete digital transformation?</b></p>
                           <p>Mrinmoy: Digital transformation involves software, hardware, and humanware. It should be regarded as a management transformation, not simply moving processes online. It will also fundamentally change corporate culture and people's mindsets. Change is not easy. I think that digital transformation should be a continuous, gradual change rather than a drastic one. It is a change to the business, and people who work in the business should be given time to adapt and guided in changing together.</p>
                           <p>For digital transformation to succeed, it must be understood that it will not minimize the value of humans but assist in minimizing intermediaries so that each person can demonstrate his or her abilities more fully while gaining more skills. After all, technology exists for humans, not humans for the sake of technology. Humans are masters, and technology is the servant. However, people shouldn’t rush to adopt digital transformation due to FOMO (fear of missing out).</p>
                           <p>Again, digital transformation is about changing corporate culture. It can’t be successful based on the FOMO attitude that we have to do something just because Amazon, Alibaba, and Google do it. Remember that they succeeded in digital transformation because the employees and corporate culture gladly embraced it and the changes it created.</p>
                           <p>Digital transformation should start with the questions of "what" and "how" as well as "why" it is needed. Blindly following others is not going to make you succeed in digital transformation. Another important thing to remember is that digital transformation should not be attempted just for its own sake but to solve specific problems. This is where it’s crucial to ask the “why” question. If digital transformation cannot solve the problems identified, there is no need to proceed. Don’t forget which problems you are trying to solve. You need to ask yourself whether the solution is more like a vitamin (nice to have but also not a problem to be without it) or like a painkiller (definitely something you need).</p>
                           <p>An important question for a company considering digital transformation concerns finances. What is the cost of not adopting digital transformation? If that cost is too high,then you must start the transformation. If that cost is relatively low, then digital transformation can probably wait.</p>
                           <p>The Golden Circle, proposed by leadership expert Simon Sinek, shows how to cooperate, trust, and change. Organizations need to know what they are doing, how they are doing it, and, especially, why they are doing it. This knowledge will make them more likely to succeed.</p>
                           <p><b>Q: Many companies want to create their own digital platforms. Do you think it’s better to build your own or work on shared platforms?</b></p>
                           <p>Mrinmoy: I think that desire comes from company ego or self-centered thinking, rather than being customer-centric. I don't think everyone needs to create a digital platform, considering the whole ecosystem. Currently, there are more than 500 IoT platforms. Each company says its platform is the best, but that’s not really a customer-focused attitude. It’s more like saying, “We have capital and resources, so we’ll just create a platform and see how it works.” Creating an ecosystem is more important than just watching different actors make their own platform versions. It’s important to remember that the entire ecosystem cannot work properly with everyone in individual silos.</p>
                           <p><b>Q: There are concerns that many activities are being carried out locally as the COVID-19 pandemic closes borders and causes major disruptions in international trade and supply chains. There are also fears that FDI will decrease, leading to more localization to minimize dependence on overseas partners. From this viewpoint, what kind of future should companies prepare for?</b></p>
                           <p>Mrinmoy: I believe that will only happen in the short term and eventually the situation will normalize. Consider competitive advantage, one of the basic economic concepts. Doing everything alone means that any competitive advantage is bound to decrease. In the long run, collaborating with a partner who does something better is a win–win situation. We need to ask if it really makes sense to try to do everything on our own. If a company makes decisions based on in-house politics instead of on facts and reality, it should be remembered that its customers will ultimately pay the price. In the short run, this may look great and seem plausible, but in the long run it will be hard to achieve a sustainable success. If quality and price goals can’t be met, it's actually an insult to customers. Ultimately, going it alone will hurt organizations that make such political decisions.</p>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>


                           </ul>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <PressReleaseLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default PressReleaseArea;