const TeamOne = () => {
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/team/details/joon-chung.jpg" alt="" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h3>Joon CHUNG, Ph.D.</h3>
                           <span>Founder, CEO & Chairman of the Board, SOLiD, Inc.</span>
                        </div>
                        <p>Dr. Joon Chung is the founding CEO and Chairman of SOLiD, since its inception in November 1998. SOLiD, a KOSDAQ listed enterprise, is currently a global leader in proving in-building wireless coverage solutions to customers in over 30 countries.</p>
                        <p>In 2003, Dr. Chung was recognized as one of 40 technology pioneers by the World Economic Forum (Davos Forum). In 2015, he was elected as Chairman of the Korea Venture Business Association, the leading industry association of over 13,000 technology companies in the nation. After serving as Chairman of the association between 2015-2017, he continues to hold the position of Honorary Chairman.</p>
                        <p>To date, Dr. Chung continues to give back to the community in various capacities. Dr. Chung received his BS degree, summa cum laude, in Electronics Engineering from Seoul National University in 1986, and his MS and PhD degrees in Electrical Engineering from Stanford University in 1988 and 1993, respectively.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamOne;