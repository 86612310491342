import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Header from '../../components/shared/Header';
import ServicesIoTDetailsArea from './ServicesIoTDetailsArea/ServicesIoTDetailsArea';

const ServicesIoT = () => {
   return (
      <>
         <PageHelmet pageTitle="Internet of Things (IoT)" />

         <Header/>
         <CommonPageHeader
            title="Internet of Things (IoT)"
            subtitle="Our Services"
            />
         <ServicesIoTDetailsArea />
         <Footer />
      </>
   );
};

export default ServicesIoT;