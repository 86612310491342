import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyLeftSideBar from '../../CaseStudies/CaseStudiesArea/CaseStudyLeftSideBar';

const CaseStudyArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                     <div className="blog__text mb-45">
                           <h4>Overview</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <p>
                              <b>A global biopharmaceutical company collaborated with SOLiD Inspire to enhance its smart factory infrastructure.</b>
                           </p>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Challenges</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Silos:</b> Isolated data across departments.</li>
                              <li><b>Documentation:</b> Incomplete records hindered maintenance.</li>
                              <li><b>Misalignment:</b> Discrepancies between central and site teams.</li>
                              <li><b>Undefined Processes:</b> Lack of clarity in operations.</li>
                              <li><b>Manual Inefficiencies:</b> Challenges in knowledge transfer.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Goals</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>Assess 10 manufacturing lines and 69 assets.</li>
                              <li>Identify risks and improvement opportunities.</li>
                              <li>Upgrade equipment for better connectivity.
                              </li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Outcomes</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>26 high-impact actions were identified; 42 recommendations were developed.</li>
                              <li>Created asset inventory and digital maturity scores.</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Deliverables</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li>Asset Inventory Database</li>
                              <li>Maturity Scores</li>
                              <li>Risk Assessment</li>
                              <li>Network Architecture Diagram</li>
                              <li>Strategic Roadmap</li>
                           </ul>
                        </div>
                        <div className="blog__text mb-45">
                           <h4>Development Phases</h4>
                        </div>
                        <div className="blog__list mb-45">
                           <ul>
                              <li><b>Phase 1:</b> Building Resilience</li>
                              <li><b>Phase 2:</b> Creating Sustainability</li>
                              <li><b>Phase 3:</b> Going for Gold</li>
                           </ul>
                        </div>
                        <div>                           
                           <Link to="/assets/case-studies/solid-inspire-case-study-healthcare.pdf" target='_blank' className='card download-casestudy stretched-link'>
                              <p className='mb-0'>Download <br /> Healthcare Industy<br /> Case Stady</p>
                              <img src="/assets/img/icon/download-pdf.png" className='ml-30 p-2 img-fluid' alt="" />                           
                           </Link>
                        </div>
                        <div className="blog__text mb-40">
                        </div>


                     </div>
                  </div>

                  <CaseStudyLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default CaseStudyArea;