import React from 'react';
import { Link } from 'react-router-dom';

const BlogLeftSideBar = () => {
    return (
        <>
            <div className="col-xl-4 col-lg-4">
                <div className="blog__sidebar">
                    {/* <div className="sidebar__widget mb-50 ">
                        <div className="sidebar__widget-content">
                            <div className="search">
                                <form action="#">
                                    <input type="text" placeholder="Search..." />
                                    <button type="button"><i > <FaSearch /> </i></button>
                                </form>
                            </div>
                        </div>
                    </div> */}
                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Recent Blogs</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc-post">
                                <ul>
                                    {/* <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/21-things-i-learned-in-2021"><img src="assets/img/blog/rc/rc-1.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/21-things-i-learned-in-2021">21 Things I learned in 2021</Link></h6>
                                             <div className="rc-meta"><span>Jan 01, 2022</span> </div> 
                                        </div>
                                    </li> */}
                                     {/* <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/generosity-is-also-letting-others-give"><img src="assets/img/blog/rc/rc-2.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/generosity-is-also-letting-others-give">Generosity is also letting others give</Link>
                                            </h6>
                                        </div>
                                    </li> */}
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/from-awareness-to-action-practical-steps-for-sustainable-transformation"><img src="assets/img/blog/rc/rc-16.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/from-awareness-to-action-practical-steps-for-sustainable-transformation">From Awareness to Action: Practical Steps for Sustainable Transformation</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/unveiling-sustainable-investment-opportunities-why-msci-matters-in-a-greener-future"><img src="assets/img/blog/rc/rc-15.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/unveiling-sustainable-investment-opportunities-why-msci-matters-in-a-greener-future">Unveiling Sustainable Investment Opportunities: Why MSCI Matters in a Greener Future</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/why-should-your-business-care-about-esg-transformation"><img src="assets/img/blog/rc/rc-14.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/why-should-your-business-care-about-esg-transformation">Why should your business care about esg transformation?</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/combating-climate-change-sustainable-businesses-can-be-heroes-too"><img src="assets/img/blog/rc/rc-13.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/combating-climate-change-sustainable-businesses-can-be-heroes-too">Combating Climate Change: Sustainable Businesses Can Be Heroes Too!</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/sustainable-supply-chains-building-resilience-and-transparency-in-the-business-world"><img src="assets/img/blog/rc/rc-12.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/sustainable-supply-chains-building-resilience-and-transparency-in-the-business-world">Sustainable Supply Chains: Building Resilience and Transparency in the Business World</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/embrace-sustainability-a-practical-guide-to-eco-friendly-living"><img src="assets/img/blog/rc/rc-11.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/embrace-sustainability-a-practical-guide-to-eco-friendly-living">Embrace Sustainability: A Practical Guide to Eco-Friendly Living</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/a-pms-adventures-with-journey-module-of-myprism"><img src="assets/img/blog/rc/rc-10.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/a-pms-adventures-with-journey-module-of-myprism">A PM’s Adventures with Journey Module of MyPrism</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/myprism-symphony-orchestrating-success-in-a-complex-world-a-project-managers-journey"><img src="assets/img/blog/rc/rc-9.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/myprism-symphony-orchestrating-success-in-a-complex-world-a-project-managers-journey">MyPrism Symphony: Orchestrating Success in a Complex World - A Project Manager's Journey</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/myprism-action-from-chaotic-mind-to-mastermind"><img src="assets/img/blog/rc/rc-8.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/myprism-action-from-chaotic-mind-to-mastermind">MyPrism Action: From Chaotic Mind to Mastermind</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/enhancing-business-resilience"><img src="assets/img/blog/rc/rc-7.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/enhancing-business-resilience">Enhancing Business Resilience: Strategies for Adapting to Climate Change</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024"><img src="assets/img/blog/rc/rc-6.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/sustainability-starts-at-home-how-to-live-a-greener-life-in-2024">Sustainability Starts at Home: How to Live a Greener Life in 2024</Link>
                                            </h6>
                                        </div>
                                    </li> 
                                     <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/my-ten-cents-on-leadership"><img src="assets/img/blog/rc/rc-5.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/my-ten-cents-on-leadership">My Ten Cents on Leadership</Link>
                                            </h6>
                                        </div>
                                    </li>
                                    {/* <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/blogDetails"><img src="assets/img/blog/rc/rc-7.jpg"
                                                alt="rc-post" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6><Link to="/blogDetails">Google take latest step & Catch the black SEO</Link>
                                            </h6>
                                        </div>
                                    </li>  */}
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Services</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="cat-link">
                                <ul>
                                    <li><Link to="/enterprise-architecture">Enterprise Architecture</Link></li>
                                    <li><Link to="/information-security">Information Security</Link></li>
                                    <li><Link to="/platform-development">Platform Development</Link></li>
                                    <li><Link to="/internet-of-value">Internet of Value</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="sidebar__widget mb-60 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Recent Comments</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc__comments">
                                <ul>
                                    <li className="d-flex mb-25">
                                        <div className="rc__comments-icon mr-30">
                                            <i ><FaRegComment style={{ marginTop: '-2px' }} /></i>
                                        </div>
                                        <div className="rc__comments-content">
                                            <h6>Justin Case</h6>
                                            <p>My lady mush hanky panky young delinquent.!</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-25">
                                        <div className="rc__comments-icon mr-30">
                                            <i ><FaRegComment style={{ marginTop: '-2px' }} /></i>
                                        </div>
                                        <div className="rc__comments-content">
                                            <h6>Eric Widget</h6>
                                            <p>My lady mush hanky panky young delinquent.!</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-25">
                                        <div className="rc__comments-icon mr-30">
                                            <i ><FaRegComment style={{ marginTop: '-2px' }} /></i>
                                        </div>
                                        <div className="rc__comments-content">
                                            <h6>Penny Tool</h6>
                                            <p>My lady mush hanky panky young delinquent.!</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="sidebar__widget mb-50 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Popular Tags</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="tags">
                                <Link to="/blogDetails">The Saas</Link>
                                <Link to="/blogDetails">Pix Saas Blog</Link>
                                <Link to="/blogDetails">Landing</Link>
                                <Link to="/blogDetails">UI/UX Design</Link>
                                <Link to="/blogDetails">Branding</Link>
                                <Link to="/blogDetails">Animation</Link>
                                <Link to="/blogDetails">Design</Link>
                                <Link to="/blogDetails">Ideas</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default BlogLeftSideBar;