import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { Link } from "react-router-dom";

const SustainabilityESGLead = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
               {/* <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 mb-85 text-center">
                        <h2>As a heading to this page add this - We understand hardware software & peopleware</h2>
                     </div>
                  </div>
               </div> */}
               <div className="row">
                  <div className="col-md-12">
                     <div className="section__content wow fadeInUp" data-wow-delay=".2s">
                        <p className='lead mb-3'>"<i>If working apart, we're a force powerful enough to destabilize our planet, surely working together, we are powerful enough to save it</i>" </p>
                        <p className='mb-4'>- Sir David Attenborough</p>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="section__content wow fadeInUp mr-30" data-wow-delay=".2s">
                        <p>Climate change, and its impact, have become the biggest challenge facing humanity. Tackling this effectively will require adaptation of existing lifestyles and adoption of new behaviors, by people across the planet.</p>
                        <p>Every business enterprise, organization &company in the world will be impacted by the effects of climate change, either directly or indirectly. Adopting sustainable measures has become a crucial component of the response to this urgent problem. These measures include conscious efforts to lessen the ecological impact, lower greenhouse gas emissions, and practice environmental stewardship.</p>
                        <p>Sustainable solutions are now being seen as a matter of survival for many businesses. That is the reason most enterprises are gearing up to create massive changes in their business processes. </p>
                        <p>Sustainable transformation in business is the incorporation of environmental, social, and governance concepts (ESG) within a company's primary operations, strategies, and decision-making. This entails striking the right balance between commercial success and a dedication to social responsibility, environmental stewardship, and governance. </p>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="section__content wow fadeInUp ml-30" data-wow-delay=".2s">
                        <p><b>At a glance, there are many reasons why your business should be looking forward to such a transformational change:</b></p>
                     <div className="section__list mb-30">
                        <ul>
                           <li ><span><i > <FaCheck /> </i>Risk management</span></li>
                           <li ><span><i > <FaCheck /> </i>Long-term profit</span></li>
                           <li ><span><i > <FaCheck /> </i>Innovation and competitive edge</span></li>
                           <li ><span><i > <FaCheck /> </i>Brand reputation</span></li>
                           <li ><span><i > <FaCheck /> </i>Attracting diverse talent</span></li>
                           <li ><span><i > <FaCheck /> </i>Meeting stakeholder expectations</span></li>
                        </ul>
                     </div>                   
                        <p>One can say that sustainable transformation is a good strategic business play not just a moral imperative. In recent times, it has been seen that companies that actively embrace change, can create far more value than the rest</p>
                        <p>But sustainability transformation impacts every aspect of a business – be it process, people, or technology. This makes it complex and daunting for businesses to find a starting point and chart a roadmap. </p>
                     </div>
                  </div>
                  <div className="col-md-12">
                     <div className="section__content wow fadeInUp" data-wow-delay=".2s">
                        <p className='lead mb-3 mt-4'>To make the task at hand simpler, we have created a series of videos that walk the viewers through the why, what, and how of sustainability transformation. 
                       </p>
                        <p className='mb-3 mt-4'><Link to="/about-sustainability" className='btn-link ml-3'>Read on to know more</Link></p>
                     </div>
                  </div>
               </div>
              
            </div>
         </section>
      </>
   );
};

export default SustainabilityESGLead;